import React, { useState } from 'react';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import Images from '../../static/images';
import axios from 'axios';
import {handleError} from '../../services/axios';
import {TitleHeader} from '../../components/header';

const Reviews = () => {
	const { state }		= useLocation();
	const { property }   = state || {};
	const avgRating5	=	100;
	const avgRating4	=	70;
	const avgRating3	=	50;
	const avgRating2	=	30;
	const avgRating1	=	20;
	
  return (
  	<div className="">
    	<TitleHeader title={"MR Irrigation Charging Station"} />
	    <div>
	    	<span className="text-secondory ml-4">Vijay Mandir Road</span>
	    </div>
	    <div className="container mt-2">
	    	<div className="mt-4 mb-3">
	    		<h6 className="font-weight-normal" style={{fontSize: '15px'}}>Rating & Reviews</h6>
	    		<div className="row">
		    		<div className="col-6">
		    			<p>4.8</p>
		    			<span className="star selected">
		                      <span>&#9733;</span>
		                      <span>&#9733;</span>
		                      <span>&#9733;</span>
		                      <span>&#9733;</span>
		                      <span>&#9733;</span>
	                	</span>
	                	<br/>
	                	<span className="text-secondary" style={{fontSize: '15px'}}>101 reviews</span>
		    		</div>
		    		<div className="col-6">
		    			<div className="col d-flex align-items-center">
			    			<span style={{fontSize: '10px',marginRight:'4px'}}>5</span><span className="star selected" style={{fontSize: '15px'}}>
			                      <span>&#9733;</span>
		                	</span>
		                	<div class="progress" style={{ flexGrow: 1 }}>
			                      <div className="progress-bar" role="progressbar" 
								        aria-valuenow={avgRating5}
								        aria-valuemin="0" 
								        aria-valuemax="100" 
								        style={{ width: `${avgRating5}%`,backgroundColor: '#f44336' }}>
			                    	<span class="sr-only">70% Complete</span>
			                      </div>
		                      </div>
	                	</div>
	                	<div className="col d-flex align-items-center">
			    			<span style={{fontSize: '10px',marginRight:'4px'}}>4</span><span className="star selected">
			                      <span>&#9733;</span>
		                	</span>
		                	<div class="progress" style={{ flexGrow: 1 }}>
			                      <div className="progress-bar" role="progressbar" 
								        aria-valuenow={avgRating4} 
								        aria-valuemin="0" 
								        aria-valuemax="100" 
								        style={{ width: `${avgRating4}%`,backgroundColor: '#f44336' }}>
			                    	<span class="sr-only">70% Complete</span>
			                      </div>
		                      </div>
	                	</div>
	                	<div className="col d-flex align-items-center">
			    			<span style={{fontSize: '10px',marginRight:'4px'}}>3</span><span className="star selected">
			                      <span>&#9733;</span>
		                	</span>
		                	<div class="progress" style={{ flexGrow: 1 }}>
			                      <div className="progress-bar" role="progressbar" 
								        aria-valuenow={avgRating3} 
								        aria-valuemin="0" 
								        aria-valuemax="100" 
								        style={{ width: `${avgRating3}%`,backgroundColor: '#f44336' }}>
			                    	<span class="sr-only">70% Complete</span>
			                      </div>
		                      </div>
	                	</div>
	                	<div className="col d-flex align-items-center">
			    			<span style={{fontSize: '10px',marginRight:'4px'}}>2</span><span className="star selected">
			                      <span>&#9733;</span>
		                	</span>
		                	<div class="progress" style={{ flexGrow: 1 }}>
			                      <div className="progress-bar" role="progressbar" 
								        aria-valuenow={avgRating2}
								        aria-valuemin="0" 
								        aria-valuemax="100" 
								        style={{ width: `${avgRating2}%`,backgroundColor: '#f44336' }}>
			                    	<span class="sr-only">70% Complete</span>
			                      </div>
		                      </div>
	                	</div>
	                	<div className="col d-flex align-items-center">
			    			<span style={{fontSize: '10px',marginRight:'4px'}}>1</span><span className="star selected">
			                      <span>&#9733;</span>
		                	</span>
		                	<div class="progress" style={{ flexGrow: 1 }}>
			                      <div className="progress-bar" role="progressbar" 
								        aria-valuenow={avgRating1} 
								        aria-valuemin="0" 
								        aria-valuemax="100" 
								        style={{ width: `${avgRating1}%`,backgroundColor: '#f44336' }}>
			                    	<span class="sr-only">70% Complete</span>
			                      </div>
		                      </div>
	                	</div>
		    		</div>
		    	</div>
	    	</div>
	    	
			<h6 className="font-weight-normal" style={{fontSize: '15px'}}>Customer Reviews</h6>
			<div className="row reviews-list mt-2">
		    	<div className="col-12 px-0">
		    		<div className="card shadow border-top mb-3">
					  <div className="card-body">
					    <div className="row justify-content-center">
						    <div className="col float-left">
						    	<span className="text-secondary font-weight-normal mb-1" style={{fontSize: '12px'}}>Username</span>
						    </div>
						    <div className="col-auto">
						    	<span className="text-secondary font-weight-normal mb-1" style={{fontSize: '12px'}}>2 days ago</span>
						    </div>
			            </div>
			            <div className="justify-content-center row">
					    	<div className="col">
			            		<div className="stars mt-3" style={{maxWidth: '11px'}}>
				                	{[1, 2, 3, 4].map((star) => (
					                  <span
					                    key={star}
					                    className="star selected">
					                    {star === 5 ? (
					                      <span className="emoji">&#9733;</span>
					                    ) : (
					                      <span>&#9733;</span>
					                    )}
					                  </span>
					                ))}
				            	</div>
			            	</div>
			            	<div className="col-auto" style={{marginTop: '15px'}}>
			            		<span className="text-secondary" style={{fontSize: '12px'}}>Tata nexon EV</span>
			            	</div>
					    </div>
					  </div>
					</div>
					<div className="card shadow border-top mb-3">
					  <div className="card-body">
					    <div className="row justify-content-center">
						    <div className="col float-left">
						    	<span className="text-secondary" style={{fontSize: '12px'}}>Username</span>
						    </div>
						    <div className="col-auto">
						    	<span className="text-secondary" style={{fontSize: '12px'}}>2 days ago</span>
						    </div>
			            </div>
			            <div className="justify-content-center row">
			            	<div className="col ml-2 mt-1">
			            		<span className="btn btn-sm shadow text-black-50 mr-2 mb-2">Fast Charging</span>
			            		<span className="btn btn-sm shadow text-black-50 mr-2 mb-2">Ease of use</span>
			            		<span className="btn btn-sm shadow text-black-50 mr-2 mb-2">Amenities</span>
			            		<span className="btn btn-sm shadow text-black-50 mr-2 mb-2">Customer Support</span>
			            		<span className="btn btn-sm shadow text-black-50 mr-2 mb-2">Cleanliness</span>
			            		<p style={{fontSize: '12px'}}>good charging expirence</p>
			            	</div>
		            	</div>
			            <div className="justify-content-center row">
					    	<div className="col mt-1">
			            		<div className="stars mt-2" style={{maxWidth: '11px'}}>
				                	{[1, 2, 3].map((star) => (
					                  <span
					                    key={star}
					                    className="star selected">
					                    {star === 5 ? (
					                      <span className="emoji">&#9733;</span>
					                    ) : (
					                      <span>&#9733;</span>
					                    )}
					                  </span>
					                ))}
				            	</div>
			            	</div>
			            	<div className="col-auto" style={{marginTop: '15px'}}>
			            		<span className="text-secondary" style={{fontSize: '12px'}}>Tata nexon EV</span>
			            	</div>
					    </div>
					  </div>
					</div>
					<div className="card shadow border-top mb-3">
					  <div className="card-body">
					    <div className="row justify-content-center">
						    <div className="col float-left">
						    	<span className="text-secondary font-weight-normal mb-1" style={{fontSize: '12px'}}>Username</span>
						    </div>
						    <div className="col-auto">
						    	<span className="text-secondary font-weight-normal mb-1" style={{fontSize: '12px'}}>2 days ago</span>
						    </div>
			            </div>
			            <div className="justify-content-center row">
					    	<div className="col">
			            		<div className="stars mt-3" style={{maxWidth: '11px'}}>
				                	{[1, 2, 3, 4, 5].map((star) => (
					                  <span
					                    key={star}
					                    className="star selected">
					                    {star === 5 ? (
					                      <span className="emoji">&#9733;</span>
					                    ) : (
					                      <span>&#9733;</span>
					                    )}
					                  </span>
					                ))}
				            	</div>
			            	</div>
			            	<div className="col-auto" style={{marginTop: '15px'}}>
			            		<span className="text-secondary" style={{fontSize: '12px'}}>Tata nexon EV</span>
			            	</div>
					    </div>
					  </div>
					</div>
					<div className="card shadow border-top mb-3">
					  <div className="card-body">
					    <div className="row justify-content-center">
						    <div className="col float-left">
						    	<span className="text-secondary font-weight-normal mb-1" style={{fontSize: '12px'}}>Username</span>
						    </div>
						    <div className="col-auto">
						    	<span className="text-secondary font-weight-normal mb-1" style={{fontSize: '12px'}}>2 days ago</span>
						    </div>
			            </div>
			            <div className="justify-content-center row">
					    	<div className="col">
			            		<div className="stars mt-3" style={{maxWidth: '11px'}}>
				                	{[1, 2].map((star) => (
					                  <span
					                    key={star}
					                    className="star selected">
					                    {star === 5 ? (
					                      <span className="emoji">&#9733;</span>
					                    ) : (
					                      <span>&#9733;</span>
					                    )}
					                  </span>
					                ))}
				            	</div>
			            	</div>
			            	<div className="col-auto" style={{marginTop: '15px'}}>
			            		<span className="text-secondary" style={{fontSize: '12px'}}>Tata nexon EV</span>
			            	</div>
					    </div>
					  </div>
					</div>
		    	</div>
			</div>
	    </div>
    </div>
  );
};

export default Reviews;