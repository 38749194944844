import React, { useState } from 'react';

import Images from '../static/images';
import {getMobileOperatingSystem} from '../components/functions';

const Filter = ({chkOpen,setChkOpen}) => {

// const [chkOpen, setChkOpen] = useState('');
const [activeTab, setActiveTab] = useState('myVehicles');
const [selectedCard, setSelectedCard] = useState(null);

  const handleClose = () => {
    setChkOpen('');
    
  };
  
  const handleCardClick = (index) => {
  	setSelectedCard((prevSelected) => (prevSelected === index ? null : index));
  };

const device = getMobileOperatingSystem();

  return (
  	<>
    
      <div className={`filters_wrapper ${chkOpen}`}>
        <div className={`overlay ${chkOpen}`}
          onClick={handleClose}
        ></div>
        <div className='filters_inner_wrapper' style={{ position: 'relative', zIndex: '2', background: '#fff' }}>
          <div className='filters_head'>
            <div className='backbtn' onClick={handleClose}>
              <img src={Images.icon_back} className="icon-back"/>
            </div>
            <h6>Filters</h6>
            <div className='right_div' style={{ color: '#8cc152' }}>
              Reset All
            </div>
          </div>
          <div className='filters_main_box'>
            <div className='left_box'>
              <span
                className={`sm_text ${activeTab === 'myVehicles' ? 'active' : ''}`}
                onClick={() => setActiveTab('myVehicles')}
              >
                My Vehicles
              </span>
              <span
                className={`sm_text ${activeTab === 'connectors' ? 'active' : ''}`}
                onClick={() => setActiveTab('connectors')}
              >
                Connectors
              </span>
              <span
                className={`sm_text ${activeTab === 'chargerType' ? 'active' : ''}`}
                onClick={() => setActiveTab('chargerType')}
              >
                Charger Type
              </span>
              <span
                className={`sm_text ${activeTab === 'availablity' ? 'active' : ''}`}
                onClick={() => setActiveTab('availablity')}
              >
                Availablity
              </span>
              <span
                className={`sm_text ${activeTab === 'chargers' ? 'active' : ''}`}
                onClick={() => setActiveTab('chargers')}
              >
                Chargers
              </span>
              <span
                className={`sm_text ${activeTab === 'network' ? 'active' : ''}`}
                onClick={() => setActiveTab('network')}
              >
                Network
              </span>
              <span
                className={`sm_text ${activeTab === 'offers' ? 'active' : ''}`}
                onClick={() => setActiveTab('offers')}
              >
                Offers
              </span>
              <span
                className={`sm_text ${activeTab === 'available' ? 'active' : ''}`}
                onClick={() => setActiveTab('available')}
              >
                Available
              </span>
              <span
                className={`sm_text ${activeTab === 'autoCharge' ? 'active' : ''}`}
                onClick={() => setActiveTab('autoCharge')}
              >
                AutoCharge
              </span>
              <span
                className={`sm_text ${activeTab === 'accessType' ? 'active' : ''}`}
                onClick={() => setActiveTab('accessType')}
              >
                Access Type
              </span>
              <span
                className={`sm_text ${activeTab === 'amenities' ? 'active' : ''}`}
                onClick={() => setActiveTab('amenities')}
              >
                Amenities
              </span>
              <span
                className={`sm_text ${activeTab === 'ratings' ? 'active' : ''}`}
                onClick={() => setActiveTab('ratings')}
              >
                Ratings
              </span>
            </div>
            <div className='right_box'>
              {activeTab === 'myVehicles' && (
                <div className='catagory_box active container'>
                  <span className='filter_catagory_text'>Select Vehicle</span>
                  <div className="row text-center">
	                  <div className="col-6 col-md-3">
		                <div className="card shadow mb-3" style={{ maxHeight: '100px' }}>
		                  <div className="card-body">
		                    <img src={Images.nexon_ev} alt="vehicle name" className="img-fluid"/>
		                    <h6 className="mb-0 font-weight-normal text-dark" style={{fontSize: '12px'}}>
		                      Tata Nexon Ev
		                    </h6>
		                  </div>
		                </div>
		              </div>
		              <div className="col-6 col-md-3">
		                <div className="card shadow mb-3" style={{ maxHeight: '100px' }}>
		                  <div className="card-body">
		                    <img src={Images.nexon_ev} alt="vehicle name" className="img-fluid"/>
		                    <h6 className="mb-0 font-weight-normal text-dark" style={{fontSize: '12px'}}>
		                      Tata Nexon Ev
		                    </h6>
		                  </div>
		                </div>
		              </div>
	              </div>
                </div>
              )}
              {activeTab === 'connectors' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Select Connectors</span>
                  <div className="row text-center">
                	<div className="col-6">
	                  <div className="avatar avatar-50 mb-1">
	                	<img src="https://www.svgrepo.com/show/503303/ev-plug-ccs-combo.svg" />
	                  </div>
	                  <span className="small">Type 1</span>
                    </div>
                    <div className="col-6">
	                  <div className="avatar avatar-50 mb-1">
	                	<img src="https://www.svgrepo.com/show/503303/ev-plug-ccs-combo.svg" />
	                  </div>
	                  <span className="small">Type 1</span>
	                </div>
                  </div>
                </div>
              )}
              {activeTab === 'chargerType' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Select Type</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          AC
				        </label>
				      </div>
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio2" />
				        <label className="lable-checkbox" htmlFor="radio2">
				          DC
				        </label>
				      </div>
				    </div>
                </div>
              )}
              {activeTab === 'availablity' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Availablity</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          Open Now
				        </label>
				      </div>
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio2" />
				        <label className="lable-checkbox" htmlFor="radio2">
				          Open 24X7
				        </label>
				      </div>
				    </div>
                </div>
              )}
              {activeTab === 'chargers' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Select Chargers</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          All Chargers
				        </label>
				      </div>
				    </div>
                </div>
              )}
              {activeTab === 'network' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Select Network</span>
                </div>
              )}
              {activeTab === 'offers' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Select Offers</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          Free
				        </label>
				      </div>
				    </div>
                </div>
              )}
              {activeTab === 'available' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Select Available Chargers</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          Available
				        </label>
				      </div>
				    </div>
                </div>
              )}
              {activeTab === 'autoCharge' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>AutoCharge</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          Enabled
				        </label>
				      </div>
				    </div>
                </div>
              )}
              {activeTab === 'accessType' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Select Type</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          Public
				        </label>
				      </div>
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio2" />
				        <label className="lable-checkbox" htmlFor="radio2">
				          Captive
				        </label>
				      </div>
				    </div>
                </div>
              )}
              {activeTab === 'amenities' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Amenities</span>
                  <div className="row text-center mb-4 mt-2">
                	<div className="col-6">
	                	<img src={Images.toilet} />
	                  <span className="small">Restroom</span>
                    </div>
                    <div className="col-6" style={{right:'30px'}}>
	                	<img src={Images.cafe} />
	                  <span className="small">Cafe</span>
	                </div>
                  </div>
                  <div className="row text-center">
                	<div className="col-6">
	                	<img src={Images.store} />
	                  <span className="small">Store</span>
                    </div>
                    <div className="col-6" style={{right:'30px'}}>
	                	<img src={Images.car_care} />
	                  <span className="small">Car Care</span>
	                </div>
                  </div>
                </div>
              )}
              {activeTab === 'ratings' && (
                <div className='catagory_box active'>
                  <span className='filter_catagory_text'>Rating</span>
                  <div className="container mt-1">
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio1" />
				        <label className="lable-checkbox" htmlFor="radio1">
				          Rating 4.5+
				        </label>
				      </div>
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio2" />
				        <label className="lable-checkbox" htmlFor="radio2">
				          Rating 4.0+
				        </label>
				      </div>
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio2" />
				        <label className="lable-checkbox" htmlFor="radio2">
				          Rating 3.5+
				        </label>
				      </div>
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio2" />
				        <label className="lable-checkbox" htmlFor="radio2">
				          Rating 3.0+
				        </label>
				      </div>
				      <div className="form-check" style={{paddingLeft: '6px'}}>
				        <input type="radio" name="radioGroup" id="radio2" />
				        <label className="lable-checkbox" htmlFor="radio2">
				          Rating 2.0+
				        </label>
				      </div>
				    </div>
                </div>
              )}
            </div>
          </div>
          <button className='echoBtn' onClick={handleClose}>
            Apply Filter
          </button>
        </div>
      </div>
	</>
  );
};

export default Filter;
