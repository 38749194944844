import React,{useEffect,useState} from 'react';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import axios from 'axios';
import {handleError} from '../../services/axios';
import {success ,error} from '../../components/notification';
import Images from '../../static/images';
import {TitleHeader} from '../../components/header';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import RingLoader from '../../components/ringLoader';
import {getMobileOperatingSystem} from '../../components/functions';


const showBottomSheet = () => {
    document.querySelector('.bottom-sheet-wrapper').classList.toggle('show');
    document.querySelector('.bottom-sheet-wrapper').classList.toggle('d-none');
	// document.body.classList.toggle("overflow-hidden");
};


const Charger = () => {
	const navigate		= useNavigate();
	const { state }		= useLocation();
	const { property }   = state || {};
	const [TempProperty, setProperty] = useState(null);
	const [chargers, setChargers] = useState([]);
	const [myconnector, setConnector] = useState({});
	const [chargetype, setChargetype] = useState('units');
	const [charging_value, setChargeValue] = useState(0);
	const [price, setPrice] 			   = useState(0);
	const [gst] 						   = useState(18);
	const [wallet ,setWallet]			   = useState(null);
	const [BookMark ,setBookMark]		   = useState(false);
	const [params , setParams]		  = useState({charging_type:"units",charging_value:0,charging_price:5});
	const [loader, setLoader] = useState(true);
	const [vehicles, setVehicles] = useState([]);
	const [defaultVehicles, setDefaultVehicles] = useState({});
	
    const [selectedCard, setSelectedCard] = useState(null);


	const GetCharger = () => {
 		axios.get(`/get-charger`,{params:{property_id:property.property_id}}).then(res => {
			let data = res.data;
			setChargers(data.chargers)
			setBookMark(data.bookmark)
			
			let dafaultV = data.vehicles.filter(row => row.default == 1);
			if(dafaultV.length == 0)
				dafaultV = {vehicle_name:"Select Vehicles",vehicle_id:null};
			else
				dafaultV = dafaultV[0];
				
			setDefaultVehicles(dafaultV)
			setVehicles(data.vehicles)
			
			let wallet = data.wallet;
			wallet.balance =  (wallet.balance * 0.847457627).toFixed(2);
			setWallet(wallet)
			setLoader(false)
		}).catch(handleError)
	}
	
	 useEffect(() => {
    	if(property.hasOwnProperty('id')){
		    GetCharger()
		    setProperty(property)
    	}else{
    		 window.history.go(-1)
    	}
	}, [property]); //
	
	const onSelectConnector = (charger_id , connector) =>{
		if(defaultVehicles.vehicle_id == null){
			error("Please select a vehicle.");
			return
		}
		connector.charger_id = charger_id;
		setConnector(connector)
	}
	
	const onRangeInputSave = (type,value) => {
		let price = 0;
		if(type == "units")
			price = (value[1] * myconnector.price_per_unit);
		if(type == "amount")
			price = value[1];

		setChargeValue(value[1]);
		setPrice(price);
	}
	
	const onStartTransaction  = () => {
		 let value  =  charging_value;
		 if(chargetype == "amount")
			 value  = (value / myconnector.price_per_unit).toFixed(2) ;
			
		 let params = {
		 	charge_type				: "units",
		 	charging_value			: value,
		 	charger_id  			: myconnector.charger_id,
		 	connector_id    		: myconnector.connector_id,
		 	oem_connector_number    : myconnector.oem_connector_number
		 }
		 axios.post('/transaction/remote-start',params).then(res => {
		 	 let data = res.data;
		 	 if(data.success){
		 	 	params.invoice_number = data.invoice_number;
		 		navigate("/next-charging",{state:{params:params,property_id:property.id}})	
		 	 }else{
		 	 	error(data.message);
		 	 }
		 }).catch(handleError)
	}
	
	const goTolocation = () => {
		let latitude = property.latitude;
		let longitude = property.longitude;
		 if (latitude && longitude) {
	      const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
	      window.open(url, '_blank')
	    } else {
	      alert('Please enter both latitude and longitude.');
	    }
	}
	
	const SaveBookMark = (value) => {
		 axios.post('/save-bookmark',{status:value,property_id:property.property_id}).then(res => {
		 	let data = res.data;
		 	if(data.success)
		 		success(data.message);
		 	else
				error(data.message);
		 }).catch(handleError)
		setBookMark(value)
	}
	
	const checkGunStatus = (status) => {
 		if(status == "available" || status == "preparing")
			return true
	
		return false;	
	} 
	
	const goToNextChargingScreen = () => {
		  let params = {
		 	charge_type				: chargetype,
		 	charging_value			: charging_value,
		 	charger_id  			: myconnector.charger_id,
		 	connector_id    		: myconnector.connector_id,
		 	oem_connector_number    : myconnector.oem_connector_number
		 }
		 
		return navigate("/next-charging",{state:{params:params,property_id:property.id}})	
	}
	
	const getConnectStyle = (status) => {
		if(status == "available")
			return 'card shadow border-0 mb-2'
		else if(status == "preparing")
			return 'card shadow border-0 mb-2 on-connector-preparing'
		else if(status == "charging")
			return 'card shadow border-0 mb-2 on-connector-charging'
	}
	
	const onVehicleSelectForCharging = () => {
		 	showBottomSheetForVehicle()
	}
	
    const showBottomSheetForVehicle = () => {
	    const wrapper = document.querySelector('.vehicle-bottom-sheet-wrapper');
	    wrapper.classList.toggle('show');
	    wrapper.classList.toggle('d-none');
	    // document.body.classList.toggle("overflow-hidden");
	};
  
	const handleCardClick = (index) => {
		setSelectedCard((prevSelected) => (prevSelected === index ? null : index));
	};
	const onDefaultVehicleSet = () => {
		 axios.post("/add-default-vehicle",{id:selectedCard}).then(res => {
	  		let data = res.data;
	  		if(data.success){
	  			success(data.message);
	  			GetCharger()
	  			showBottomSheetForVehicle()
	  		}else
	  			error(data.message);
  	    }).catch(handleError)
	}
	
    const device = getMobileOperatingSystem();

	return (
			<>
			<TitleHeader title={property.property_name} />
			<div className="container">
			  <div className="card mb-4 shadow">
			    <div className="card-header">
			    	<h5>{property.property_name} 
			    	
			    	  {
			    			BookMark ?
				    			<img src={Images.icon_save} style={{float: "right",height:25}} onClick={() => SaveBookMark(false)}/>
				    		:	
				    			<img src={Images.icon_unsave} style={{float: "right",height:25}} onClick={() => SaveBookMark(true)}/>
			    		}
		    		</h5>
			    	<span>{property.address}</span>
			    	<div className="mt-2">
			    	 <span className="btn btn-sm btn-light shadow mt-2 mr-2"  onClick={() => goTolocation()}>Go to location</span> 
			    	 <span className="btn btn-sm btn-light shadow mt-2 mr-2" onClick={() => navigate("/reviews",{state:{property_id:property.id}})} >Reviews</span> 
			    	 <span className="btn btn-sm btn-light shadow mt-2" onClick={() => onVehicleSelectForCharging()} >{defaultVehicles.vehicle_name}</span> 
			    		
			    	</div>
			    </div>
			  </div>
			  { loader ? <RingLoader/> :
			  <div className="row">
					  <div className="col-12">
					    <div className="card shadow-sm border-0 mb-3 wizard">
					      <div >
					    	{
					    		chargers.length > 0 ? 
					    		chargers.map((item,index) => {
					    			return <div className="card-body" key={index}>
								            {item.charger_name} - {item.charger_status}
								            <br />
								            <br />
								            {
								            	
									            item.connectors.map((connector,index2) => {
									            	return  <div className={ item.charger_status == "online" ? myconnector.id == connector.id ? "card shadow border-0 mb-2 on-connector-select"  :  getConnectStyle(connector.status) : "card shadow border-0 mb-2 on-connector-offline" } key={index2} onClick={() => item.charger_status == "online"  ?  checkGunStatus(connector.status) ? onSelectConnector(item.charger_id,connector) : null : null}>
																  <div className="card-body">
																    <div className="row">
																      <div className="col-auto pr-0">
																        <figure className="avatar avatar-50 border-0">
																          <img src={connector.icon} alt="" />
																        </figure>
																        
																      </div>
																      <div className="col align-self-center">
																        <p>
																          {connector.name}
																          <br />
																          <small><span>{connector.type}</span> | { item.charger_status == "online" ?  connector.status : "Disconnected" }</small>
																        </p>
																      </div>
																    </div>
																  </div>
															</div>
									            })
								            }
								          </div>
					    		})
					    		: null
					    	}
					        </div>
					    </div>
					  </div>
					</div>
					}
					<div class="charging-bottom-btn-box">
						<div class="padding-20-lr">
	            			<button className="saveBtn" style={{marginTop: -20}} disabled={myconnector.hasOwnProperty('id') ? false : true} onClick={() => myconnector.hasOwnProperty('id') ? showBottomSheet() : null}> {myconnector.hasOwnProperty('id')? "Charge Now" :  "Select a connector" }</button>
	            		</div>
					</div>
					
					{/* bottom sheet */}
					<div className="bottom-sheet-wrapper d-none">
						  <div className="backdrop" onClick={() =>  showBottomSheet()}  />
						  <div className="bottom-sheet">
						    <div className="loginForm" style={{paddingTop:15}}>
			        			<div className="loginForm_inner mb-0">
								    <div className="contentDiv">
								    		<div>
								        	<h6>Plug in and Press Start</h6>
									    	<ul className="nav nav-pills nav-pills-sm nav-justified mt-3 mb-3" role="tablist">
											 {/* <li role="presentation" className="nav-item" onClick={() => {setChargetype("time");setChargeValue(0)}}>
											    <div className={chargetype == "time" ? "nav-link active" : "nav-link"} >
											      Time
											    </div>
											  </li>*/}
											  <li role="presentation" className="nav-item" onClick={() => {setChargetype("units");setChargeValue(0);setPrice(0)}}>
											    <div className={chargetype == "units" ? "nav-link active" : "nav-link"} >
											      Unit
											    </div>
											  </li>
											   <li role="presentation" className="nav-item" onClick={() => {setChargetype("amount");setChargeValue(0);setPrice(0)}}>
											    <div className={chargetype == "amount" ? "nav-link active" : "nav-link"} >
											      Amount
											    </div>
											  </li>
											 {/* <li role="presentation" className="nav-item" onClick={() => {setChargetype("percentage");setChargeValue(0)}}>
											    <div className={chargetype == "percentage" ? "nav-link active" : "nav-link"} >
											      Percentage
											    </div>
											  </li>*/}
											</ul>
											</div>
											{
											chargetype == "time" ? 
												 <div class="col">
								                	<p>How much time do you want to charge?</p>
									                <div className="inputbox">
 									                     <RangeSlider  className="single-thumb" min={0} max={12} defaultValue={[0,0]} thumbsDisabled={[true,false]} rangeSlideDisabled={true} onInput={(value) => onRangeInputSave("time",value)}  />
									                </div>
									                <div className="range-lable-box">
									                	<span>0</span>
									                	<span>12hr</span>
									                </div>
								                </div>
								            : chargetype == "units" ?     
												<div class="col">
								                	<p>How many units do you need?</p>
									                <div className="inputbox">
									                     <RangeSlider key={"units"}  className="single-thumb" min={0} max={40} defaultValue={[0,0]} thumbsDisabled={[true,false]} rangeSlideDisabled={true} onInput={(value) => onRangeInputSave("units",value)}  />
									                </div>
									                <div className="range-lable-box">
									                	<span>0</span>
									                	<span>40</span>
									                </div>
								                </div> 
							                : chargetype == "percentage" ?     
							                	<div class="col">
								                	<p>How much percentage do you want to add?</p>
									                <div className="inputbox">
									                     <RangeSlider key={"percentage"} className="single-thumb" min={0} max={100} defaultValue={[0,0]} thumbsDisabled={[true,false]} rangeSlideDisabled={true} onInput={(value) => onRangeInputSave("percentage",value)}  />
									                </div>
									                <div className="range-lable-box">
									                	<span>0</span>
									                	<span>100%</span>
									                </div>
								                </div>
						                	: chargetype == "amount" ?     
							                	<div class="col">
								                	<p>How much amount do you want to spand?</p>
									                <div className="inputbox">
									                     <RangeSlider key={"amount"}  className="single-thumb" min={0} max={parseInt(wallet.balance)} defaultValue={[0,0]} thumbsDisabled={[true,false]} rangeSlideDisabled={true} onInput={(value) => onRangeInputSave("amount",value)}  />
									                </div>
									                <div className="range-lable-box">
									                	<span>0</span>
									                	<span>{wallet.balance}</span>
									                </div>
								                </div>
						                	: null
										}
										<div className={"mt-3"}>
						                	<div className="range-lable-box">
							                	<div>
							                		<span className="Heading-15px" style={{float:'left'}}>Estimated Price</span> {" "}
							                		<span style={{fontSize:12}}>{" "}(Inc taxes)</span>
						                		</div>
							                	<span>₹ {(price + (price * 0.18)).toFixed(2)}</span>
							                </div>
					                	</div>
										{
											wallet != null && wallet.balance > 50 ? 
							                <div className={"mt-3"}>
						                		<button className="saveBtn" onClick={() => onStartTransaction()}>Proceed to Pay</button>
						                	</div>
					                	: 
						                	<div className={"mt-3"}>
							                	<div className="row">
								                	<div className="col-6">
								                		<div>Ecoplug Wallet</div>
								                		<div className={"badge badge-light"}>0 Credits</div>
							                		</div>
								                	<div className="col-6">
								                		<button className="saveBtn" onClick={() => navigate("/wallet",{state:{popopen:true}})}>Add Credits</button>
								                	</div>
								                	<div className="col">
								                		<span className="text-danger" style={{fontSize:10}}>Please add ₹50.00 to your wallet, for seamless charging.</span>
								                	</div>
								                </div>
						                	</div>
										}
						            </div>
						         </div>
							</div>
						  </div>
					</div>
				</div>
				
				 <div className={"vehicle-bottom-sheet-wrapper d-none"} >
			    	<div className="backdrop" onClick={() =>  showBottomSheetForVehicle()}  />
			    		<div className="vehicle-bottom-sheet" className={ device == "iOS" ? "vehicle-bottom-sheet-ios" : "vehicle-bottom-sheet"}>
			    			<div className="" style={{padding:15}}>
				                <div className="mb-0">
				                    <div className="">
				                     <div className="row" >
			                        	 {
			                        		vehicles.map((vehicle, index) => (
			                        			 
										              <div  key={index}  className="col-6 col-md-3 text-center">
										                <div className={`card shadow mb-3 ${selectedCard === vehicle.id ? 'vehicleSelected' : ''}`} 
										                	onClick={() => handleCardClick(vehicle.id)}>
										                  <div className="card-body">
										                    <img
										                      src={vehicle.vehicle_image}
										                      alt={vehicle.vehicle_name}
										                      className="img-fluid mb-2"
										                      style={{ maxHeight: '70px' }}
										                    />
										                    <h6 className="mt-3 mb-0 font-weight-normal text-dark">
										                      {vehicle.vehicle_name}
										                    </h6>
										                    <span>{vehicle.vehicle_registration_number}</span>
										                  </div>
										                </div>
										                </div>
									                
									            ))
									            
			                        	 }
				                        </div>
								                <div className="row">
								                	<div className={ defaultVehicles.vehicle_id != null || vehicles.length > 0 ? "col-6" : "col-12"}>
								                		<button className="saveBtn btn-primary" onClick={() => navigate("/manage-vehicles")}>Add New</button>
								                	</div>
								                	{
								                	   defaultVehicles.vehicle_id != null || vehicles.length > 0 ?
										                	<div className="col-6">
										                		<button className="saveBtn" onClick={() => onDefaultVehicleSet()}>Apply</button>
										                	</div>
									                	: null
								                	}
							                	</div>	
				                    </div>
				                </div>
				            </div>
			    		</div>
			    	</div>
		</>
	)
}

export default Charger;