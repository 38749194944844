import React,{useEffect,useState} from 'react';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Images from '../../static/images';
import {handleError} from '../../services/axios';
import {TitleHeader} from '../../components/header';

const PaymentDetails = () => {
	const navigate		= useNavigate();

	const { state }			  = useLocation();
	const { transaction_id,fromScreen }		= state || {};	
	const [details ,setDetails]  = useState(null);

	const GetPaymentDetails = () => {
 		axios.get(`/payment-details`,{params:{payment_id:transaction_id}}).then(res => {
			let data = res.data;
			setDetails(data.data)
		}).catch(handleError)
	}
	
	 useEffect(() => {
	    GetPaymentDetails()
	}, []); //
	
	
	return (
		<>
		{ fromScreen != undefined && fromScreen == "charging" ? null  : <TitleHeader title={"SESSION DETAILS"} />}
		{
			details == null ? "" :
			<div className="container">
				<div className="card">
					<div className="card-header text-center">
						<div className="align-items-center" style={{display:"flex",justifyContent: "space-between"}}>
							<img src={Images.logo} className="invoice-logo" />
							<div className="col-md-12 text-left">
								<h6>Ecoplug Energy India Limited</h6>
								<span>Balla Boda, Kh. No. 147, Rath Nagar, Vijay Mandir Road, Alwar, Rajasthan 301001</span>
							</div>
						</div>
						<h5 className="mt-2">Vehicle Charging Invoice No. {details.invoice_number}</h5>
					</div>
					<div className="card-body" style={{border: "2px dashed"}}>
						<div className="text-center">
							<h5>Booking ID</h5>
							<span>{details.booking_id}</span>
						</div>
						<div className="row align-items-center mb-5">
						      <div className="col pr-0">
						        <h6 className="font-weight-normal mb-1">Charger Name</h6>
						        <p className="text-mute  text-secondary" style={{width: "85%"}}>{details.charger_name}</p>
						      </div>
						      <div className="col-auto text-right">
					              <h6>{details.connector_name}</h6>
					              <p>CCS 1</p>
						      </div>
					    </div>
				    	<div className="row align-items-center">
						      <div className="col pr-0">
						        <h6 className="font-weight-normal mb-1">Vehicle Details</h6>
						        <span className="text-mute  text-secondary">{ details.vehicle != null ? details.vehicle.vehicle_name : "N/A"}</span>
						      </div>
						      <div className="col-auto text-right">
					              <h6 >SOC Details</h6>
					              <span>Start : {details.soc_start} </span>
					              <span>End : {details.soc_end} </span>
						      </div>
					    </div>
					    <div className="row align-items-center mt-4">
						      <div className="col pr-0">
						        <p className="text-mute  text-secondary">Comment</p>
						      </div>
					    </div>
					     <div className="border-dashed mt-4" /> 
					     <div className="row">
						      <div className="col-12 item-flex">
						        <span className="text-mute  text-secondary">Units</span>
						        <span className="text-mute  text-secondary">{details.units_consumed}</span>
						      </div>
						      <div className="col-12  item-flex">
						        <span className="text-mute  text-secondary">Start Time</span>
						        <span className="text-mute  text-secondary">{details.start_date_time}</span>
						      </div>
						      <div className="col-12  item-flex">
						        <span className="text-mute  text-secondary">Stop Time</span>
						        <span className="text-mute  text-secondary">{details.end_date_time}</span>
						      </div>
						      <div className="col-12  item-flex">
						        <span className="text-mute  text-secondary">Cost</span>
						        <span className="text-mute  text-secondary">{details.amount}</span>
						      </div>
						      <div className="col-12  item-flex">
						        <span className="text-mute  text-secondary">IGST</span>
						        <span className="text-mute  text-secondary">{details.sgst + details.cgst}</span>
						      </div>
						      <div className="col-12  item-flex">
						        <span className="text-mute  text-secondary">Total Cost</span>
						        <span className="text-mute  text-secondary">{details.total_amount}</span>
						      </div>
					    </div>
					     <div className="border-dashed" /> 

					    <div className="row mt-4 ">
					    	<div className="col-12 text-center">
					    		<h6>Location : {details.property.property_name}</h6>
					    		<span>{details.property.address} {details.property.city} {details.property.state} {details.property.pincode} {details.property.country}</span>
					    	</div>
					    </div>
					</div>
				</div>
					
					{ fromScreen != undefined && fromScreen == "charging" ? <button className="saveBtn mt-5" onClick={() =>  navigate("/add-review",{state:{property_id : details.property_id,property_name:details.property_name}})}>Review and Rate</button> : null}
			</div>
        	
		}
		</>
	)
}

export default PaymentDetails;