import React, { useState,useEffect,useRef } from 'react';
import {io} from 'socket.io-client';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import {TitleHeader} from '../../components/header';
import axios from 'axios';
import {handleError} from '../../services/axios';
import {success ,error} from '../../components/notification';
import SlideBtn from '../../components/SlideButton';
import RangeSlider from 'react-range-slider-input';


var batteryInner = document.getElementsByClassName('batteryInner')[0];

const Charging = () => {
	const ref = useRef();
	const navigate		= useNavigate();
	const { state }		= useLocation();
	const { params,property_id }    = state || {};

 

	const [meterStart , setMeterStart] = useState(null);
	const [connectorStatus , setConnectorStatus] = useState({});
	const [transaction , setTransaction] = useState({});
	const [stoptransaction , setStopTransaction] = useState(false);
		
	useEffect(() => {
	  
	   onGetCurrentTransaction();
	}, []); //
	
	const onGetCurrentTransaction = () => {
		axios.post("/get-ongoing-charging",params).then(res => {
			let data = res.data;
			if(data.success){
	  			setTransaction(data.transaction);
				GetSocketData(data.transaction);
			}
		}).catch(handleError)
	} 
	
	const GetSocketData = (transaction) => {
 		 let xdata = {
			oem_connector_number: transaction.oem_connector_number,
			charger_serial_number:transaction.charger_serial_number
	   }
	   
	   let socket =  io("wss://chargingsocket.eeil.online",{ transports: ["websocket"]});
	   socket.on("connection",() => {
	   	 
	   })
	   socket.on(`meter_value_${xdata.charger_serial_number}_${xdata.oem_connector_number}`,(data) => {
	   		if(data == null)
	   			return;
	   		let messageData = data;
	   		let obj = JSON.parse(messageData);
	   		
	   		let charge_speed = 20;
	   		let time_90_left = (((90 - obj.soc) * charge_speed) / 60);
	   		let time_10_left = ( ((100 - obj.soc) * charge_speed * 5) /60) ;
	   		if(obj.soc <= 90)
 	   			obj.time_left = (parseFloat(time_90_left) + parseFloat(time_10_left)).toFixed(0);
 	   		else
 	   			obj.time_left = parseFloat(time_10_left).toFixed(0);
 	   		setMeterStart(obj)
 	   	    	 
	   })
	    
	   socket.on(`status_notification_${xdata.charger_serial_number}_${xdata.oem_connector_number}`,(data) => {
	   		if(data == null)
	   			return;
	   	    let messageData = data;
	   		let obj  = JSON.parse(messageData);
  	   		setConnectorStatus(obj)
	   		if(obj.status == "Preparing" || obj.status == "Charging")
	   			socket.emit(`meter_values`,xdata)
   			else if(obj.status == "SuspendedEVSE" || obj.status == "SuspendedEV" )
				window.history.go(-1)
 	   })
	   
	   socket.on(`transaction_stop_${transaction.booking_id}`,(data) => {
	   		if(data == null)
	   			return;
	   	    let messageData = data;
	   		let obj  = JSON.parse(messageData);
   			navigate("/payment-details",{state:{transaction_id : transaction.id,fromScreen:"charging"}})
	   })
	  
	   socket.emit(`charging_status`,xdata)
   	   socket.emit(`transaction_stop`,transaction)
	   socket.on("connect_error", (err) => {
		  console.log(`connect_error due to ${err.message}`);
		});
	}
	
	const onStopTransaction = () => {
		let params = {
		 	transaction_id : transaction.id,
		 }
		 setStopTransaction(true)
		 axios.post('/transaction/remote-stop',params).then(res => {
		 	 let data = res.data;
		 	 if(data.success){
		 		success(data.message);
		 	 }else
		 		error(data.message);
	
		 }).catch(handleError)
	}
	
	const render_left_time = (min) => {
		var hours = Math.floor(min / 60);          
    	var minutes = min % 60;
    	
    	if(hours > 0)
    		return `${hours} Hours ${minutes} Min`;
    	else if(minutes >= 0)
    		return `${minutes} Min`;	
	}
	const render_charging_screen = () => {
		return <>
			<TitleHeader title={"Your Ev is charging"}/>
			<div className="charging-main-box">
				<div className="row text-center" style={{paddingBottom: 80}}>
					 <div className="col">
						<img src={transaction.user_vehicle_image} className="charging-car-img" />
					 </div>
				</div>
				<div className="row text-center justify-content-center battry-div-margin">
					<div className="batteryOuter battry-div main-charging-box">
					   <span className="percentage-text">{meterStart.soc}%</span>
                       <RangeSlider  className="single-thumb " min={0} max={100} value={[0,meterStart.soc]} thumbsDisabled={[false,false]} rangeSlideDisabled={true}   />
					</div>
				</div>
				<div className="row text-center justify-content-center">
						 
			    		 <div className="card col-5 m-2" >
								<h4>₹ {meterStart.amount}</h4>
								<span className="text-secondary">Current Price</span>
						  </div>
						  <div className="card col-5 m-2" >
								<h4>{meterStart.unit} KWh</h4>
								<span className="text-secondary">Units Consumed</span>
						  </div> 
						  <div className="card col-5 m-2" >
								<h4>{meterStart.power_output} kW</h4>
								<span className="text-secondary">Output Power</span>
						  </div> 
						  {
							  !isNaN(meterStart.time_left)  ?
								  <div className="card col-5 m-2" >
										<h4>{render_left_time(meterStart.time_left)}</h4>
										<span className="text-secondary">Time Remaining</span>
								  </div>
							   : null
						  }
					 
 				</div> 
 				<div className="charging-bottom-btn-box">
					<div className="padding-20-lr">
						<div className="">
						</div> 
						<SlideBtn onStopTransaction={onStopTransaction} disabled={stoptransaction} />
 			    	</div>
			    </div>
			</div>
		</>
	}
	return (
		<div className="container-fluid">
		
			<div>
				{
					meterStart == null ? 
					<div className="overlay" style={{marginLeft: "-15px"}}>
						<h5 style={{color:"#fff"}} className="chargin-lable-box">Please wait for {connectorStatus.hasOwnProperty('status') && connectorStatus.status != undefined ? connectorStatus.status : "initiating charging"}</h5>
					  <div className="spinner"></div>
					  <div className="label">Please Wait</div>
					</div>
					: 
					render_charging_screen()
				
				}
			</div>
		</div>
	)
	
}

export default Charging;