import React, { useState } from 'react';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import moment from 'moment';
import Images from '../../static/images';
import axios from 'axios';
import {handleError} from '../../services/axios';
import {TitleHeader} from '../../components/header';
import {success ,error} from '../../components/notification';

let tags = [
	"Fast Charging",
	"Ease of use",
	"Amenities",
	"Customer Support",
	"Cleanliness"
];

const AddReview = () => {
	const navigate		= useNavigate();

	const { state }		= useLocation();
	const { property_id,property_name }   = state || {};
	const [selectedRating, setSelectedRating] = useState(0);
	const [comment, setComment] = useState(null);
	const [inputtags, setInputTags] = useState([]);

	const handleRating = (rating) => {
	    setSelectedRating(rating);
	};
	
	
	const onInputChange = (type,value) => {
		setComment(value)
	}	
	const onSaveTags = (item) => {
			if(inputtags.includes(item)){
				const index = inputtags.indexOf(item);
				if (index > -1) { // only splice array when item is found
				  inputtags.splice(index, 1); // 2nd parameter means remove one item only
				}
			}else
				inputtags.push(item);
		setInputTags(inputtags);
	}	
	const SaveBookMark = () => {
		 let params = {
		 	property_id:property_id,
		 	comment:comment,
		 	tags:inputtags,
		 	rate:selectedRating
		 }
		 axios.post('/add-reviews',params).then(res => {
		 	let data = res.data;
		 	if(data.success){
		 		success(data.message);
		 		navigate("/")
		 	}else
				error(data.message);
		 }).catch(handleError)
	}
	
  return (
  	<div className="">
    	<TitleHeader title={"Add Review"} />
	    <div>
	    	<h6 className="h5 ml-4">{property_name}</h6>
	    	<span className="text-secondory ml-4">{moment().format("DD MMM YYYY ,hh:mm A")}</span>
	    </div>
	    <div className="container">
	      <div className="col-12 col-md-6">
	        <div className="card shadow mt-3 mb-3">
	          <div className="card-body">
	            <div className="row align-items-center">
	              <h6 className="h6 ml-3 mt-2">How was your charging experience?</h6>
	            </div>
	            <div className="row justify-content-center">
	              <div className="stars mt-3">
	                {[1, 2, 3, 4, 5].map((star) => (
	                  <span
	                    key={star}
	                    className={`star ${selectedRating >= star ? 'selected' : ''}`}
	                    onClick={() => handleRating(star)}
	                  >
	                    {star === 5 ? (
	                      <span className="emoji">&#9733;</span>
	                    ) : (
	                      <span>&#9733;</span>
	                    )}
	                  </span>
	                ))}
	              </div>
	            </div>
	            <div className="row justify-content-center">
	              <p className="rating-text mt-2">
	                {selectedRating === 0
	                  ? ''
	                  : selectedRating === 5
	                  ? 'Excellent'
	                  : `Rating: ${selectedRating}`}
	              </p>
	            </div>
	          </div>
	        </div>
	        {/*
	        <div className="card shadow mt-3 mb-3">
	          <div className="card-body">
	            <div className="row align-items-center">
	              <h6 className="h6 ml-3 mt-2">Upload Photo</h6>
	            </div>
	            <div className="row justify-content-center">
	              <div className="col">
	            	<p className="text-left text-mute">Upload charger photo or just photo of the actual place. Help others find the place faster.</p>
	              </div>
	              <div className="col-auto" style={{right:'15px'}}>
	            	<img src={Images.icon_camera} />
	              </div>
	            </div>
	            
	          </div>
	        </div>
	        */}
	        <div className="card shadow mt-3 mb-3">
	          <div className="card-body">
	            <div className="row align-items-center">
	              <h6 className="h6 ml-3 mt-2">What Impressed you?</h6>
	            </div>
	            <div className="row justify-content-center">
	              <div className="col">
	            	{
	            		tags.map((item,index) => {
	            			return <button className={ inputtags.includes(item) ? "active btn-sm btn-primary shadow mr-2 mb-2" : "btn-sm shadow text-black-50 mr-2 mb-2"} key={index} onClick={() => onSaveTags(item)} >{item}</button>
	            		})
	            	}
	            	<textarea className="form-control mt-2"  onChange={(e) => onInputChange("comment",e.target.value)} rows="3"></textarea>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	      <div class="charging-bottom-btn-box">
			<div class="padding-20-lr">
	    		<button className="saveBtn" style={{marginTop: 10}} onClick={() => SaveBookMark()} >Add Review</button>
		      </div>
		    </div>
	    </div>
    </div>
  );
};

export default AddReview;