import React, { useState } from 'react';
import Images from '../../static/images';
import axios from 'axios';
import {handleError} from '../../services/axios';
import { Link,useParams,useNavigate  } from 'react-router-dom';
import Filter from '../../components/filter';

const chargers = {
  All: [
    { name: "Ecoplug Energy India Limited office", address: 'Rath Nagar' },
    { name: "Ecoplug Energy India Limited office", address: 'Rath Nagar' },
    { name: "Ecoplug Energy India Limited office", address: 'Rath Nagar' },
    { name: "Ecoplug Energy India Limited office", address: 'Rath Nagar' },
  ],
  Availble: [
    { name: "Ecoplug Energy India Limited office", address: 'Rath Nagar' },
    { name: "Ecoplug Energy India Limited office", address: 'Rath Nagar' },
  ]
};

const SelectCharger = () => {
  const navigate = useNavigate();
  const [chkOpen, setChkOpen] = useState('');

  const [selectedTab, setSelectedTab] = useState('All');
  const [properties, setProperties] = useState([]);
  const [filter_properties, setFilterProperties] = useState([]);

  const handleTabClick = (tab) => {
  	if(tab == "AVAILABLE"){
  		let filter = properties.filter(row => row.status == 1);
  		setFilterProperties(filter)
  	}else if(tab == "UNAVAILABLE"){
  		let filter = properties.filter(row => row.status == 0);
  		setFilterProperties(filter)
  	}else{
  		setFilterProperties(properties)
  	}
    setSelectedTab(tab);
  };
  
  const SearchChargingProperty = (text) => {
	 	axios.get(`/search-station`,{params:{search:text}}).then(res => {
			let data = res.data;
			 setProperties(data.properties)
		}).catch(handleError)
  }
  
  const onSearchValue = (text) => {
  		if(text.length > 3)
  			SearchChargingProperty(text);
  }
  
  	const goToCharger = (item) => {
		navigate("/charger",{state:{property:item}});
	} 
	
	
	
  return (
    <div className="wrapper mt-4">
    	 <div className="container mt-4">
	    	<div className="row mb-2">
		    	<div className="col-auto item-flex">
		    		<img src={Images.icon_back} class="icon-back" onClick={() => window.history.go(-1)}/>
		    		<div className="search-input-box input-group">
		    		<div className="search-input-prepend input-group-prepend">
					    <span className="" id="inputGroupPrepend3">
					      <img style={{height:40}} src="https://maxartkiller.com/website/Fimobile/Fimobile-HTML/img/search.png" />
					    </span>
					  </div>
					  <input
					    type="text"
					    className="form-control"
					    placeholder="Search charger,city,state...."
					    style={{margin: "auto"}}
					    onChange={(e) => onSearchValue(e.target.value)}
					  />
					   
					</div>
{/*					<img src={Images.filter} style={{maxHeight:'20px',marginLeft:'10px'}} onClick={() => setChkOpen('active')}/>
*/}		    	</div>
			</div>
		</div>

      <div className="">
        <div className="d-flex tabs-fixed">
            <button
              className={`btn btn-sm tab-content tab-pane shadow-sm ${selectedTab === "All" ? 'navTabActive' : ''}`}
              onClick={() => handleTabClick("All")}
            >
              {"All"}
              {selectedTab === "All" && (
	              <div className="custom-close-icon"></div>
            	)}
            </button> 
            <button
              className={`btn btn-sm tab-content tab-pane shadow-sm ${selectedTab === "AVAILABLE" ? 'navTabActive' : ''}`}
              onClick={() => handleTabClick("AVAILABLE")}
            >
              {"AVAILABLE"}
              {selectedTab === "AVAILABLE" && (
	              <div className="custom-close-icon"></div>
            	)}
            </button>
            <button
              className={`btn btn-sm tab-content tab-pane shadow-sm ${selectedTab === "UNAVAILABLE" ? 'navTabActive' : ''}`}
              onClick={() => handleTabClick("UNAVAILABLE")}
            >
              {"UNAVAILABLE"}
              {selectedTab === "UNAVAILABLE" && (
	              <div className="custom-close-icon"></div>
            	)}
            </button>
        </div>
        <div className="container mt-4">
          <div className="row text-center">
            {filter_properties.map((item, index) => (
              <div key={index} className="col-12 col-md-6" onClick={() => goToCharger(item)}>
                <div className="charger-card shadow border-0 mb-3">
				  <div className="card-details">
				    <div className="card-header custom-card-header">
				      <h6 className="card-title text-left">{item.property_name}<br/><p>{item.address}</p></h6>
				        <div className="public-badge">{item.visibility.toUpperCase()}</div>
				    </div>
				    <div className="card-footer custom-card-footer">
				      <div className="card-status">
				        <span className={item.status ?  `badge badge-success` : `badge badge-danger`}>{item.status ? "Available" : "Unavailable"}</span>
				      </div>
				       {
					      item.rate != null ? 
						      <div className="rating">
						        <span className="star-icon">★</span>
						        {item.rate.toFixed(1)}
						      </div>
						      : null
				      }
				      {/*
				      <div className="distance">9 kms</div>
				      <div className="charger-types">
				          <span className="charger-type">AC</span>
				      </div>
				      */}
				    </div>
				  </div>
				</div>
              </div>
            ))}
          </div>
          
        </div>
      </div>
      <Filter chkOpen={chkOpen} setChkOpen={setChkOpen}/>
    </div>
  );
};

export default SelectCharger;