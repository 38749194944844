function  AllNumbersKeyUp (event){
    // Prevent default behavior if Enter key is pressed
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter' || event.key === '.' || event.key === '+' || event.key === '-' || event.key === '*' || event.key === '/' || event.key === 'e') {
      event.preventDefault();
      
    }
  };

function  DotAndNumbersKeyUp(event){
    // Prevent default behavior if Enter key is pressed
    if ( event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter' || event.key === '+'|| event.key === '-' || event.key === '*' || event.key === '/' || event.key === 'e') {
      event.preventDefault();
     }
};

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}
 

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2)  {
var R = 6371; // km
var dLat = toRad(lat2-lat1);
var dLon = toRad(lon2-lon1);
var lat1 = toRad(lat1);
var lat2 = toRad(lat2);

var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
var d = R * c;
return d;
}

// Converts numeric degrees to radians
function toRad(Value) 
{
return Value * Math.PI / 180;
}


function getSource(){
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get('source');
}

export {
	AllNumbersKeyUp,
	DotAndNumbersKeyUp,
	getMobileOperatingSystem,
	getDistanceFromLatLonInKm,
	getSource
}  
