import React from 'react'
import Slider from 'react-touch-drag-slider'
import {getMobileOperatingSystem} from '../../components/functions';
// here we are importing some images
// but the Slider children can be an array of any element nodes,
// or your own components

function NewSlider({ distance,stations ,getLatLong,goToCharger,sliderindex}) {
	let device = getMobileOperatingSystem();
	console.log("distance",distance)
  return (
  	<div   className={ device == "iOS" ? "slideronmap-ios" : "slideronmap"}>
    <Slider 
      onSlideComplete={(i) => {
        let station = stations.filter((row,index) => index == i)[0];
	    getLatLong(station.latitude,station.longitude)
      }}
      onSlideStart={(i) => {
          
      }}
      activeIndex={sliderindex}
      threshHold={100}
      transition={0.5}
      scaleOnDrag={true}
    >
      {stations.map((item, index) => (
      <div className="container mt-4" key={index}>
          <div className="row text-center center-block">
		      <div className="col-12 col-md-6">
		          <div className="charger-card shadow border-0 mb-3" onClick={() => goToCharger(item)}>
					  <div className="card-details">
					    <div className="card-header custom-card-header">
					      <h6 className="card-title">{item.property_name}<br/><p>{item.address}</p></h6>
					        <div className="public-badge">{item.visibility.toUpperCase()}</div>
					    </div>
					    <div className="card-footer custom-card-footer">
					      <div className="card-status">
					        <span className={item.active_charger > 0  ? "badge badge-success": "badge badge-danger"}>{item.active_charger > 0 ? "Available" : "Unavailable"}</span>
					      </div>
					      {
					      item.rate != null ? 
						      <div className="rating">
						        <span className="star-icon">★</span>
						        {item.rate.toFixed(1)}
						      </div>
						      : null
					      }
					        {!isNaN(distance) ? <div className="distance">{distance} kms</div> : null }  
					      <div className="charger-types">
					          <span className="charger-type">AC</span>
					      </div>
					    </div>
					  </div>
					</div>
				</div>
			</div>
		</div>
      ))}
    </Slider>
    </div>
  )
}

export default NewSlider;