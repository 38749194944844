import React, { useState,useEffect } from 'react';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import Images from '../../static/images';
import {TitleHeader} from '../../components/header';
import axios from 'axios';
import moment from 'moment';
import {handleError} from '../../services/axios';
import RingLoader from '../../components/ringLoader';

const ChargingSession = () => {
	const navigate = useNavigate();
    const [charging_status, setChargeStatus] = useState('completed');
  	const [sessionList ,setSessionList]  = useState([]);
  	const [pendingList ,setPendingList]  = useState([]);
  	const [loader, setLoader] = useState(true);

    const GetPaymentDetails = () => {
	 	axios.get(`/charging-session-list`).then(res => {
			let data = res.data;
			let completed = []; 
			let pending = []; 
			data.data.map((item,index) => {
				if(item.status == "completed"){
					completed.push(item)
				}else{
					pending.push(item)
				}
					
			})
			setPendingList(pending)
			setSessionList(completed)
			setLoader(false)
		}).catch(handleError)
	}
	
	 useEffect(() => {
	    GetPaymentDetails()
	}, []); //

	const goToPaymentDetails = (item) => {
		navigate("/payment-details",{state:{payment_id:item.payment_id}})	
	}
	

  return (
    <div className="">
      <TitleHeader title={"CHARGING SESSION"} />
      { loader ?	<RingLoader/> : 
      <div className="container">
    		<div className="card mb-4 shadow">
			    <div className="card-header">
			    	<ul className="nav nav-pills nav-pills-sm nav-justified mt-3 mb-3" role="tablist">
					  <li role="presentation" className="nav-item" onClick={() => {setChargeStatus("pending")}}>
					    <div className={charging_status == "pending" ? "nav-link active" : "nav-link"} >
					      Pending
					    </div>
					  </li>
					  <li role="presentation" className="nav-item" onClick={() => {setChargeStatus("completed")}}>
					    <div className={charging_status == "completed" ? "nav-link active" : "nav-link"} >
					      Completed
					    </div>
					  </li>
					</ul>
			    </div>
			  </div>
	          <div className="row text-center">
	              <div className="col">
	              {
	               charging_status == "completed" ? 
	               <>
		             { 	sessionList.map((item,index) => {
		              		return <div className="card shadow   mb-3 coupon-left-border" key={index} onClick={() => goToPaymentDetails(item)}>
									   <div className="card-body">
											<div className="row align-items-center">
											      <div className="col ">
											        <h6 className="text-left font-weight-normal mb-1">{item.property_name}</h6>
											        <p className="text-left  text-mute  text-secondary" style={{width: "85%"}}>{item.address}</p>
											      </div>
											      <div className="col-auto text-right">
										              <h6>#{item.booking_id}</h6>
											      </div>
										    </div>
										    <div className="row align-items-center mt-2">
											      <div className="col">
				  							        <p className="text-left  text-mute  text-secondary mb-0" style={{width: "85%"}}>Data and Time</p>
											        <h6 className="text-left font-weight-normal mb-1">{moment(item.created_at).format("DD MMM YYYY, hh:mm a")}</h6>
											      </div>
											      <div className="col-auto text-right">
										              <h6>{item.connector_name}</h6>
											      </div>
										    </div> 
										    <div className="row align-items-center mt-2">
											      <div className="col ">
				  							        <p className="text-left  text-mute  text-secondary mb-0" style={{width: "85%"}}>Charger Name</p>
											        <h6 className="text-left font-weight-normal mb-1">{item.charger_name}</h6>
											      </div>
											      <div className="col-auto text-right">
										              <p className="mb-0">SOC Details</p>
										              <h6>Start: 36% , Stop : 38%</h6>
											      </div>
										    </div>
									   </div>
									   <div className="card-footer">
										<div className="rating text-left"> You Rate <span className="star-icon">★</span> 4.5  </div>
									   </div>
									</div>
			              	})
	            		}
	            		{
			             	sessionList.length == 0 ? 
			              		<div>
									No Charging Session
			              		</div>
			              	: null
		              	}
		              	 </>
		              	: 
		              	<>
		              	{pendingList.map((item,index) => {
	              		return <div className="card shadow   mb-3 coupon-left-border" key={index}>
								   <div className="card-body">
										<div className="row align-items-center">
										      <div className="col ">
										        <h6 className="text-left font-weight-normal mb-1">{item.property_name}</h6>
										        <p className="text-left  text-mute  text-secondary" style={{width: "85%"}}>{item.address}</p>
										      </div>
										      <div className="col-auto text-right">
									              <h6>{item.booking_id}</h6>
										      </div>
									    </div>
									    <div className="row align-items-center mt-2">
										      <div className="col">
			  							        <p className="text-left  text-mute  text-secondary mb-0" style={{width: "85%"}}>Data and Time</p>
										        <h6 className="text-left font-weight-normal mb-1">{moment(item.created_at).format("DD MMM YYYY, hh:mm a")}</h6>
										      </div>
										      <div className="col-auto text-right">
									              <h6>{item.connector_name}</h6>
										      </div>
									    </div> 
									    <div className="row align-items-center mt-2">
										      <div className="col ">
			  							        <p className="text-left  text-mute  text-secondary mb-0" style={{width: "85%"}}>Charger Name</p>
										        <h6 className="text-left font-weight-normal mb-1">{item.charger_name}</h6>
										      </div>
										      <div className="col-auto text-right">
									              <p className="mb-0">SOC Details</p>
									              <h6>Start: 36% , Stop : 38%</h6>
										      </div>
									    </div>
								   </div>
								   <div className="card-footer">
									<div className="rating text-left"> You Rate <span className="star-icon">★</span> 4.5  </div>
								   </div>
								</div>
		              	})
		              	}
		              	{
			             	pendingList.length == 0 ? 
			              		<div>
									No Pending Session
			              		</div>
			              	: null
		              	}
		              	 </>
		              }
	              </div>
	          </div>
      </div>
      }
    </div>
  );
};

export default ChargingSession;