import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

function checkWidth() {
    const elemWidth = document.getElementById("swipebtn").offsetWidth - 70;
    console.log("elemWidth",elemWidth)
    return elemWidth;
}

const SwipeButton = ({ onSwipeSuccess }) => {
  const [position, setPosition] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);
   const handlers = useSwipeable({
    onSwiping: (eventData) => {
      setIsSwiping(true);
      const newPos = Math.min(Math.max(eventData.deltaX, 0), checkWidth()); // Adjust as per your need
      setPosition(newPos);
    },
    onSwipedRight: () => {
      setPosition(checkWidth()); // Set to the max position
      setTimeout(() => {
        onSwipeSuccess();
        // setPosition(0); // Reset position after success
      }, 300); // Match with CSS transition duration
    },
    onSwiped: () => {
      setIsSwiping(false);
      if (position < checkWidth()) {
        // If not swiped completely, reset
        setPosition(0);
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  
  return (
    <div id="swipebtn" className="swipe-container text-center" {...handlers}>
      <div
        className={`swipe-button ${isSwiping ? "swiping" : ""}`}
        style={{ left: `${position}px` }}
      >
        >>
      </div>
      Slide To Stop Charging
    </div>
  );
};

const App = ({onStopTransaction,disabled}) => {
  const handleSwipeSuccess = () => {
    if(!disabled)
    	onStopTransaction();
  };

  return (
    <div className="app">
       <SwipeButton onSwipeSuccess={handleSwipeSuccess} />
    </div>
  );
};

export default App;
