import logo from './logo.svg';
import { ToastContainer, toast } from 'react-toastify';
import './assets/css/vendor.css';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from './routes';

function App() {
  return (
    <>
       <AppRoutes />
    	<ToastContainer />

    </>
  );
}

export default App;
