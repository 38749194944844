import React,{ useState ,useEffect,useMemo} from 'react';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import Images from '../../static/images';
import {axios,handleError} from '../../services/axios';
import {Link,useParams,useNavigate  } from 'react-router-dom';
import NewSlider from './newSlider'
import GeolocationComponent from './GeolocationComponent'
import Footer from '../../components/footer';
import {getDistanceFromLatLonInKm,getSource} from '../../components/functions';

// implementation of this function is needed for codesandbox example to work
// you can remove it otherwise
 
const defaultMapOptions = {
  mapTypeControl:false,
  fullscreenControl: false,
  zoomControl:false,
  streetViewControl:false,
  draggable:true,
  disabledDefaultUI:false,
  gestureHandling: "greedy",
  minZoom: 4, 
  maxZoom: 16,
  styles: [
		 {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#a3cc52"  // Greenish color for parks and vegetation
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#f4f0ec"  // Greenish color for natural landscape
        }
      ]
    },
    {
      "featureType": "poi.attraction",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#000"  // Brownish color for attractions (optional)
        }
      ]
    },
	 {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#8bcded"  // Blue color for water
        }
      ]
    }, 
    {
      "featureType": "poi",
      "stylers": [
        { "visibility" : "off" }
      ]
    },
     
    // Add more style rules as needed
  ]
};

const containerStyle = {
  width: "100%",
  height: '100vh'
};

const Dashboard = () => {
	const navigate = useNavigate();
	const { isLoaded } = useJsApiLoader({
	    id: 'google-map-script',
	    googleMapsApiKey: "AIzaSyBCU1EjZJ8ZRmWpYxaK55nAKKcehh-cTwM"
	})
	
	const [map, setMap] = React.useState(null)
    const [properties, setProperties] = useState([]);
    const [center, setCenter] = useState({});
    const [sliderindex, setSliderIndex] = useState(0);
    const [distance, setDistance] = useState(0);

	
	const onLoad = React.useCallback(function callback(map) {
    	// This is just an example of getting and using the map instance!!! don't just blindly copy!
    	
    	 const bounds = new window.google.maps.LatLngBounds(
		      new window.google.maps.LatLng(6.740, 68.147),  // SW coordinates
		      new window.google.maps.LatLng(35.674, 97.344)  // NE coordinates
		    );
		
		    // Fit the map to the bounds
	    	map.fitBounds(bounds);
	    // const bounds = new window.google.maps.LatLngBounds(center);
	    
	    setMap(map)
	    position()
	  }, [])
	  
	const getChargingStations = () => {
 		axios.get(`/charging-station`).then(res => {
 			let data = res.data;
			if(data.properties.length > 0)
				setProperties(data.properties)
		}).catch(handleError)
	}
 	 useEffect(() => {
 	    // Fetch data from an API
 	    
	    getChargingStations()
	}, []); // Empty dependency array means this effect runs only once after the initial render
 
	const onUnmount = React.useCallback(function callback(map) {
	    setMap(null)
	}, [])
	 
	const goToCharger = (item) => {
		navigate("/charger",{state:{property:item}});
	} 
	
	
	const getLatLong = (lat,long) => {
		let pos = { lat: lat, lng: long };
  		let dis =  getDistanceFromLatLonInKm(lat,long,center.lat,center.lng)
	    setDistance(dis.toFixed(1))
 	    const bounds = new window.google.maps.LatLngBounds(pos);
	    map.fitBounds(bounds, true)
	}
	  
	const onFocusSlideAddress = (index) => {
 			setSliderIndex(index)
	}
	
		 
	const goToListCharger = () => {
		navigate("/list-chargers");
	} 
	
    const position = async () => {
		const urlParams = new URLSearchParams(window.location.search);
		if(urlParams.get('lat') != null && urlParams.get('lng') != null){
			let lat =  urlParams.get('lat'); 
			let lng =  urlParams.get('lng') ;
			let center = { lat: parseFloat(lat), lng: parseFloat(lng) };
			setCenter(center);
 			if(map != null){
	 			const bounds = new window.google.maps.LatLngBounds(center);
	 		    map.fitBounds(bounds, true)
 			}
 		}else if (navigator.geolocation) {
 			 if(getSource() == "app"){
 		 			const isAndroid = /Android/i.test(navigator.userAgent);
				    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
 				    if (isAndroid) {
				      alert('Please go to Settings > Location to enable location services.');
				    } else if (isiOS) {
				      alert('Please go to Settings > Privacy > Location Services to enable location services.');
				    } else {
				      alert('Location settings cannot be opened on this platform. Please enable location services manually.');
				    }
 			 }else{
			    await navigator.geolocation.getCurrentPosition(
			      position => setCenter({ 
			        lat: position.coords.latitude, 
			        lng: position.coords.longitude
			      }), 
			      err => console.log(err)
			    );
		    	if(map != null){
				    	const bounds = new window.google.maps.LatLngBounds(center);
					    map.fitBounds(bounds, true)
		    	}
 			 }
    	}else{
    		alert("Please Allow Your Loaction.")
    	}
	  }

	 
	
	return  <>	
			
			
            <div className="dash-icon-item">
	            <div className="icon-item" onClick={() => position()}>
	            	<img className="location-icon" src={Images.loc_nav} alt="" />
	            </div> 
	            <div className="icon-item" onClick={() => goToListCharger() }>
	            	<img className="bar-icon" src={Images.icon_list} alt="" />
	            </div>	
            </div>
			<div className="main-map" >
				 {isLoaded ? (
				      <GoogleMap
		                mapContainerStyle={containerStyle}
	                	center={center} 
				        zoom={4}
				        onLoad={onLoad}
				        onUnmount={onUnmount}
			        	options={defaultMapOptions}
				
				      >
				    
				        { /* Child components, such as markers, info windows, etc. */ }
						  { 
						      properties.length > 0 ? properties.map((item,index) => {
						      	return  <Marker 
						      					key={index} 
						      					onClick={() => onFocusSlideAddress(index)}
						      					position={{lat: item.latitude, lng: item.longitude}} 
						      					icon={Images.chargerbl}
					      					/> 
						      })
						      : null
					      }
					       {
					       center != null ?
				        	<Marker 
				        		key="location"
		      					position={center} 
		      					icon={Images.icon_my_loc}
	      				   /> 
	      				   : null
					       }
				      </GoogleMap>
					) : <></>
				 }
		 </div>
 		 <NewSlider distance={distance} stations={properties} getLatLong={getLatLong} goToCharger={goToCharger} sliderindex={sliderindex}/>
		<Footer/>
		</>
	 
}

export default Dashboard;
