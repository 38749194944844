var Images = {
	"logo" : require("../assets/images/logo-black.jpeg"),
	"wallet" : require("../assets/images/wallet.png"),
	"user" : require("../assets/images/user.png"),
	"map" : require("../assets/images/map.png"),
	"support" : require("../assets/images/support.png"),
	"mapmarker" : require("../assets/images/mapmarker.png"),
	"car" : require("../assets/images/car.png"),
	"arrow" : require("../assets/images/arrow.png"),
	"profile" : require("../assets/images/profile.png"),
	"chargergr" : require("../assets/images/charger-gr.png"),
	"chargerbl" : require("../assets/images/charger-bl.png"),
	"nexon_ev"  : require("../assets/images/tata-nexon-ev.png"),
	"loc_nav"	: require("../assets/images/gps.png"),
	"icon_list" : require("../assets/images/menu.png"),
	"icon_back" : require("../assets/images/back.png"),
	"icon_credit" : require("../assets/images/credit.png"),
	"icon_debit" : require("../assets/images/debit.png"),
	"icon_my_loc" : require("../assets/images/circle.png"),
	"icon_save" : require("../assets/images/unsave.png"),
	"icon_unsave" : require("../assets/images/save.png"),
	"icon_camera" : require("../assets/images/camera_icon.png"),
	"whatsapp" : require("../assets/images/whatsapp.png"),
	"call" : require("../assets/images/call.png"),
	"write" : require("../assets/images/write.png"),
	"whatsapp_2" : require("../assets/images/whatsapp_green.png"),
	"filter" : require("../assets/images/filter.png"),
	"toilet" : require("../assets/images/toilet.png"),
	"cafe" : require("../assets/images/cafe.png"),
	"store" : require("../assets/images/store.png"),
	"car_care" : require("../assets/images/car_care.png"),
}

export default Images;