import React, { useState,useEffect } from 'react';
import Images from '../../static/images';
import {TitleHeader} from '../../components/header';
import {axios,handleError} from '../../services/axios';
import {success ,error} from '../../components/notification';
import {getMobileOperatingSystem} from '../../components/functions';

 

const SelectVehicle = () => {
  const [selectedTab, setSelectedTab]	= useState('All');
  const [selectedCard, setSelectedCard] = useState(null);
  const [vehicles, setVehicles] 		= useState(null);
  const [reg_no, setRegNo] 		= useState(null);
	
  const handleTabClick = (tab) => {
     setSelectedTab(tab);
  };
  
  const handleCardClick = (index) => {
  	setSelectedCard((prevSelected) => (prevSelected === index ? null : index));
  };
  
  useEffect(() => {
  	getAllVehicles();
  }, []); 
 

  
  const getAllVehicles = () => {
	  	axios.get("/get-all-vehicles").then(res => {
	  		let data = res.data;
		    setVehicles(data.vehicles)		
	  	}).catch(handleError)
  }
  
  const showBottomSheet = () => {
    const wrapper = document.querySelector('.vehicle-bottom-sheet-wrapper');
    wrapper.classList.toggle('show');
    wrapper.classList.toggle('d-none');
    // document.body.classList.toggle("overflow-hidden");
 };
  
  const onSaveVehicles = () => {
	  const regex = /^[A-Z]{2}[0-9]{2}[A-Z]{0,4}[0-9]{3,4}$/;
	  var result = regex.test(reg_no);                    
	  if(!result){
	  		error("please enter valid registration no.");
		    return
	  }
  	  axios.post("/add-user-vehicle",{regno:reg_no,vehicle_id:selectedCard}).then(res => {
	  		let data = res.data;
	  		if(data.success){
	  			success(data.message);
	  			showBottomSheet()
	  		}else
	  			error(data.message);
  	  }).catch(handleError)
  }
  
  const device = getMobileOperatingSystem();
  if(vehicles == null)
	return 
   return (
    <div className="">
      <TitleHeader  title={"MANAGE VEHICLES"}/>
      
      <div className="">
       <div className="d-flex justify-content-around tabs-fixed overflow-scroll-y" style={{zIndex: 1}}>
          {Object.keys(vehicles).map((tab) => (
            <button
              key={tab}
              className={`btn btn-sm tab-content tab-pane shadow-sm ${selectedTab === tab ? 'navTabActive' : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
        <div className="container mt-4">
          <div className="row text-center">
            {vehicles[selectedTab].map((vehicle, index) => (
              <div key={index} className="col-6 col-md-3">
                <div className={`card shadow mb-3 ${selectedCard === vehicle.id ? 'vehicleSelected' : ''}`} 
                	onClick={() => handleCardClick(vehicle.id)}>
                  <div className="card-body">
                    <img
                      src={vehicle.vehicle_image}
                      alt={vehicle.name}
                      className="img-fluid mb-2"
                      style={{ maxHeight: '100px' }}
                    />
                    <h6 className="mt-3 mb-0 font-weight-normal text-dark">
                      {vehicle.name}
                    </h6>
                    <span>{vehicle.battery_capacity}</span>
                  </div>
                </div>
              </div>
            ))}
             <div className="charging-bottom-btn-box">
				<div className="padding-20-lr">
	    			<button disabled={selectedCard === null} className="saveBtn"   onClick={() => showBottomSheet()}>Add Vehicle</button>
	    		</div>
			</div>
          </div>
          
        </div>
        <div className={"vehicle-bottom-sheet-wrapper d-none"} >
    	<div className="backdrop" onClick={() =>  showBottomSheet()}  />
    		<div className="vehicle-bottom-sheet" className={ device == "iOS" ? "vehicle-bottom-sheet-ios" : "vehicle-bottom-sheet"}>
    			<div className="loginForm" style={{paddingTop:15}}>
	                <div className="loginForm_inner mb-0">
	                    <div className="contentDiv">
	                        <div className="row mb-3 justify-content-center">
                                <h5 className="h6 ml-2" style={{float:'left'}}>Enter your Vehicle Number</h5>
	                        </div>
	                        <div className="inputbox">
	                            <input type="text" className="form-control validate-name" placeholder="Vehicle Number" value={reg_no} onChange={(e) => setRegNo(e.target.value)} />
	                        </div>
	                        <p className="text-left  text-mute  text-secondary">Enter your Registration no.</p>
	                        <div>
	                            <button className="saveBtn" onClick={() => onSaveVehicles()}>Submit</button>
	                        </div>
	                    </div>
	                </div>
	            </div>
    		</div>
    	</div>
      </div>
    </div>
  );
};

export default SelectVehicle;
