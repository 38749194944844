import React,{useEffect,useState} from 'react';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import axios from 'axios';
import Images from '../../static/images';
import {handleError} from '../../services/axios';
import {AllNumbersKeyUp ,DotAndNumbersKeyUp} from '../../components/functions';
import {success ,error} from '../../components/notification';

import '../../assets/loginstyle.css';

 

const VerifyOTP = () => {
	const inputs = document.querySelectorAll(".otp-input-fields input");
	const navigate		= useNavigate();
	const { state }		= useLocation();
	const { mobile_no } = state || {};
	const [tempotp, tempOTP] = useState([]);
	const [otp, setOTP] = useState("");
 
	const SubmitVerifyOTP = () => {
 		axios.post(`https://api.eeil.online/api-v1/user/verify-otp`,{mobile_no:mobile_no,req_otp:otp}).then(res => {
			let data = res.data;
			if(data.success){
				success(data.message);
				localStorage.setItem("user",JSON.stringify(data.user));
				if(data.user.name == null || data.user.email == null )
		    		navigate("/basic-details");
		    	else
		    		navigate("/");
			}else{
				error(data.message);
			}
		}).catch(handleError)
	}

	const onChangeInput = (pos,e) => {
		const input = e.target;
	    let value = input.value; 
	    let isValidInput = value.match(/[0-9]/gi);
 	    input.value = ""; 
 	    input.value = isValidInput ? value : "";
	    tempotp[pos]= isValidInput ? value : "";
	    let fieldIndex = input.dataset.index;;

 	    if (fieldIndex < 5 && isValidInput) {
 	        input.nextElementSibling.focus();
	    }
	    
	    if (e.key === "Backspace" && fieldIndex > 0) {
	        input.previousElementSibling.focus();
	    }
 	    if (fieldIndex == 5 && isValidInput) {
	         SaveOtp()
	    }
	}
	
	useEffect(() => {
  	}, []); 
 

	 
	
	const SaveOtp = () => {
		
	    let otp = "";
	    tempotp.forEach((input) => {
	        otp += input;
	    });
	    setOTP(otp)
	} 

	return (
		 <div className="loginForm">
	        <div className="loginForm_inner mb-0">
	            <div className="contentDiv">
	            	<h1 className="Heading">OTP Verification</h1>
	                <h2 className="Heading-15px">Please Enter the Verication Code sent to +91{mobile_no}</h2>
	                <div className="otp-input-fields">
					    <input type="number" className="otp__digit otp__field__1" data-index="0"  maxLength="1" onKeyUp={(value) => onChangeInput("0",value)} />
					    <input type="number" className="otp__digit otp__field__2" data-index="1" maxLength="1" onKeyUp={(value) => onChangeInput("1",value)} />
					    <input type="number" className="otp__digit otp__field__3" data-index="2" maxLength="1" onKeyUp={(value) => onChangeInput("2",value)}/>
					    <input type="number" className="otp__digit otp__field__4" data-index="3" maxLength="1" onKeyUp={(value) => onChangeInput("3",value)} onKeyDown={AllNumbersKeyUp} />
					    <input type="number" className="otp__digit otp__field__5" data-index="4" maxLength="1" onKeyUp={(value) => onChangeInput("4",value)} onKeyDown={AllNumbersKeyUp} />
					    <input type="number" className="otp__digit otp__field__6" data-index="5"  maxLength="1" onKeyUp={(value) => onChangeInput("5",value)} onKeyDown={AllNumbersKeyUp} />
						
					</div>
					<span>
	                	<button className="saveBtn" onClick={() => SubmitVerifyOTP()}>Verify OTP</button>
	                </span>
	            </div>
	        </div>
	    </div>
	)
}
 
export default VerifyOTP;