import { toast,Bounce } from 'react-toastify';

function success(message){
	toast.success(message, {
		position: "top-center",
		autoClose: 3000,
 		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
 		theme: "colored",
		transition: Bounce,
	});
}

function error(message){
	toast.error(message ,{
		position: "top-center",
		autoClose: 3000,
 		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
 		theme: "colored",
		transition: Bounce,
	});
}

export {success,error}