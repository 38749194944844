import React,{ useState } from 'react';
// import '../assets/footer.css';
import { Link } from 'react-router-dom';
import Images from '../static/images';

function Footer(){
	return(
		<div className="footer" style={{paddingTop:0}}>
			  <div className="no-gutters">
			    <div className="col-auto mx-auto">
			      <div className="row no-gutters" style={{justifyContent:"space-around"}}>
			        <div className="col-auto">
				        <Link to="/">
				          <button className="btn btn-link-default ">
			                 <img src={Images.map} className="login-logo" />
				          </button>
			        	</Link>
			        </div>
			        <div className="col-auto">
			        	<Link to="/wallet">
				          <button className="btn btn-link-default">
			                 <img src={Images.wallet} className="login-logo" />
				          </button>
			        	</Link>
			        </div>
			        <div className="col-auto">
			        <Link to="/profile">
			          <button className="btn btn-link-default ">
		                 <img src={Images.user} className="login-logo" />
			          </button>
			          </Link>
			        </div>
			        {/*<div className="col-auto">
			    	 <Link to="/contact-us">
			          <button className="btn btn-link-default active">
		                 <img src={Images.support} className="login-logo" />
			          </button>
			          </Link>
			        </div>*/}
			      </div>
			    </div>
			  </div>
			</div>

	 );
}
    
export default Footer;