import React,{useEffect,useState} from 'react';
import axios from 'axios';
import {handleError} from '../../services/axios';
import Images from '../../static/images';
import {TitleHeader} from '../../components/header';
import { Link } from 'react-router-dom';
import RingLoader from '../../components/ringLoader';

const  Profile = () => {
	const [profile, setProfile] = useState({});
	const [loader, setLoader] = useState(true);
	
	const GetProfileDetails = () => {
 		axios.get(`/get-profile`,{params:{}}).then(res => {
			let data = res.data;
			setProfile(data.profile)
			setLoader(false)
		}).catch(handleError)
	}
	
	 useEffect(() => {
	    GetProfileDetails()
	}, []); //
	
	return (
		<>	
			<TitleHeader title={"PROFILE"} />
			{ loader ?	<RingLoader/> : 
			<div className="container-fluid mt-3">
		      <div className="card p-3">
		        <div className="text-center">
		          <div className="profile-pic mb-3">
		            <div className="pic-circle">
		              <img src={Images.profile} alt="" />
		            </div>
		          </div>
		          <h6 className="boldFont text-center">{profile.name}</h6>
		          <p>{profile.email}</p>
		          <p>{profile.mobile_country_code}{profile.mobile_number}</p>
		        </div>
		      </div>
		      <div className="d-flex justify-content-around mt-2">
		    	<div className="row-2">
				  <div className="card shadow border-0 col-md-6" style={{height: '70px',width:'160px'}}>
				    <div className="card-body">
				      <div className="row">
				        <div className="col-auto pr-0">
				            <img src={Images.wallet} alt="" style={{marginTop:'5px',width:'25px'}} />
				        </div>
				        <div className="col align-self-center ml-1" style={{paddingRight:'5px'}}>
				          <p className="boldFont" style={{fontSize: '14px'}}>Wallet
				            <br />
				            <small className="text-secondary"> ₹ {profile.hasOwnProperty("balance") ? profile.balance.balance : 0}</small>
				          </p>
				        </div>
				      </div>
				      
				    </div>
				  </div>
				  <div className="card shadow border-0 col-md-6" style={{height: '70px',width:'160px'}}>
				    <div className="card-body">
				      <div className="row">
				        <div className="pr-0">
				            <img src={Images.car} alt="" style={{marginTop:'5px',width:'30px'}} />
				        </div>
				        <div className="col align-self-center ml-1">
				          <p className="boldFont" style={{fontSize: '14px'}}>Vehicle
				            <br />
				            <small className="text-secondary">{profile.vehicle}</small>
				          </p>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
		      </div>
		      
		      <div class="p-3">
		          <div className="card shadow mb-3">
			          <div className="card-header" style={{borderRadius: '5px'}}>
			        	<h6 className="boldFont">Charging</h6>
			          </div>
		            <Link to="/charging-sessions" className="list-group-item d-flex justify-content-between align-items-center nav-profile-link">
		              <span>Charging Sessions</span> <span className="arrow-right"></span>
		            </Link>
		           {/* <Link to="/" className="list-group-item d-flex justify-content-between align-items-center nav-profile-link">
		              <span>Manage RFID</span> <span className="arrow-right"></span>
		            </Link>*/}
		          </div>
		          <div className="card shadow mb-3">
				      <div className="card-header" style={{borderRadius: '5px'}}>
			        	<h6 className="boldFont">Manage Vehicles</h6>
			          </div>
			        <Link to="/manage-vehicles" className="list-group-item d-flex justify-content-between align-items-center nav-profile-link">
			          <span>Manage Vehicles</span> <span className="arrow-right"></span>
			        </Link>
			      </div>
			      <div className="card shadow mb-3">
				      <div className="card-header" style={{borderRadius: '5px'}}>
			        	<h6 className="boldFont">Stations</h6>
			          </div>
			       {/* <Link to="/list-chargers" className="list-group-item d-flex justify-content-between align-items-center nav-profile-link">
			          <span>List a charger</span> <span className="arrow-right"></span>
			        </Link>
			        <Link to="/" className="list-group-item d-flex justify-content-between align-items-center nav-profile-link">
			          <span>Captive Stations</span> <span className="arrow-right"></span>
			        </Link>*/}
			        <Link to="/saved-charger-list" className="list-group-item d-flex justify-content-between align-items-center nav-profile-link">
			          <span>Saved Stations</span> <span className="arrow-right"></span>
			        </Link>
			      </div>
			      <div className="card shadow mb-3">
				      <div className="card-header" style={{borderRadius: '5px'}}>
			        	<h6 className="boldFont">Help & Support</h6>
			          </div>
			       
			        <Link to="/contact-us" className="list-group-item d-flex justify-content-between align-items-center nav-profile-link">
			          <span>Contact Us</span> <span className="arrow-right"></span>
			        </Link>
			      </div>
		        </div>
		    </div>
			}
		</>
		);
}

export default Profile;