import React, { useState,useEffect } from 'react';
import Images from '../../static/images';
import axios from 'axios';
import {handleError} from '../../services/axios';
import { Link,useParams,useNavigate  } from 'react-router-dom';
import {success ,error} from '../../components/notification';
import {TitleHeader} from '../../components/header';
import RingLoader from '../../components/ringLoader';

const SavedCharger = () => {
  const navigate = useNavigate();

  const [chargerlist, setChargerList] = useState([]);
  const [loader, setLoader] = useState(true);
	const SearchChargingProperty = (text) => {
	 	axios.get(`/user-saved-bookmark`).then(res => {
			let data = res.data;
			 setChargerList(data.properties)
			 setLoader(false)
		}).catch(handleError)
	}
  
    useEffect(() => {
		    SearchChargingProperty()
	}, []); //
	
  
	const goToCharger = (item) => {
		navigate("/charger",{state:{property:item}});
	} 
	
	
		
	const SaveBookMark = (item,value) => {
		 axios.post('/save-bookmark',{status:value,property_id:item.property_id}).then(res => {
		 	let data = res.data;
		 	if(data.success){
		 		success(data.message);
		 		SearchChargingProperty()
		 	}else
				error(data.message);
		 }).catch(handleError)
	}
  return (
      <div className="">
  		<TitleHeader title={"SAVED CHARGER"} />
  		{ loader ?	<RingLoader/> : 
        <div className="container mt-4">
          <div className="row text-center">
            {
            chargerlist.length > 0 ? 
            chargerlist.map((item, index) => (
              <div key={index} className="col-12 col-md-6">
                <div className="charger-card shadow border-0 mb-3">
				  <div className="card-details">
				    <div className="card-header custom-card-header"  onClick={() => goToCharger(item)}>
				      <h6 className="card-title text-left">{item.property_name}<br/><p>{item.address}</p></h6>
				        <div className="public-badge">{item.visibility.toUpperCase()}</div>
				    </div>
				    <div className="card-footer custom-card-footer">
				      <div className="card-status">
				        <span className={item.status ?  `badge badge-success` : `badge badge-danger`}>{item.status ? "Available" : "Unavailable"}</span>
				      </div>
	    			  <img src={Images.icon_save} style={{float: "right",height:25}} onClick={() => SaveBookMark(item,false)}/>
				      {/*
				      <div className="rating">
				        <span className="star-icon">★</span>
				        4.5
				      </div>
				      <div className="distance">9 kms</div>
				      <div className="charger-types">
				          <span className="charger-type">AC</span>
				      </div>
				      */}
				    </div>
				  </div>
				</div>
              </div>
            ))
            	:
            	
            		<div className="col text-center">
						No Saved Stations
              		</div>
            }
          </div>
          
        </div>
  		}
      </div>
  );
};

export default SavedCharger;