import {Routes , BrowserRouter as Router, Route, Navigate,Outlet,useParams   } from "react-router-dom"

import LoginScreen from '../views/login';
import VerifyOTP from '../views/login/verify_otp';
import Dashboard from '../views/dashboard';
import Wallet from '../views/wallet';
import Charger from '../views/charger';
import NextChargering from '../views/charger/charging';
import PaymentDetails from '../views/wallet/payment_details';
import Profile from '../views/user/user_profile';
import SavedCharger from '../views/user/saved_stations';

import UserDetailScreen from '../views/user/user_details_form';
import OtpScreen from '../components/otpScreen';
import HomeScreen from '../views/homescreen';
import SideBar from '../components/sidebar';
import Header from '../components/header';
import SelectVehicle from '../views/user/vehicles';
import SelectCharger from '../views/charger/list_charger';
import ChargingSession from '../views/charger/charging_sessions';
import AddReview from '../views/charger/add_review';
import ContactUS from '../views/user/contact_us';
import Reviews from '../views/charger/reviews';

const PrivateRoutes = () => {
	let  isAuthenticated = localStorage.getItem("user") == null ? false : true;
	//let  isAuthenticated =  true;
	return (
	    isAuthenticated ? 
	       <>
	    		<SideBar />
				<div className="wrapper">
					{/*<Header />*/}
					<div>
						<Outlet/>
					</div>
				</div>
 			</>
		: <Navigate to='/login'/>
	  )
}


function AppRoutes(){
	return (
			<Routes>
				<Route element={<PrivateRoutes/>}>
					<Route exact path='/' element={<Dashboard/>} />
					<Route exact path='/wallet' element={<Wallet/>} />
					<Route exact path='/charger' element={<Charger/>} />
					<Route exact path='/profile' element={<Profile/>} />
					<Route exact path='/manage-vehicles' element={<SelectVehicle/>} />
					<Route exact path='/next-charging' element={<NextChargering/>} />
					<Route exact path='/list-chargers' element={<SelectCharger/>} />
					<Route exact path='/charging-sessions' element={<ChargingSession/>} />
					<Route exact path='/payment-details'	element={<PaymentDetails/>} />
					<Route exact path='/add-review'	element={<AddReview/>} />
					<Route exact path='/saved-charger-list'	element={<SavedCharger/>} />
					<Route exact path='/contact-us'	element={<ContactUS/>} />
					<Route exact path='/reviews' element={<Reviews/>} />
					<Route exact path='/basic-details' element={<UserDetailScreen/>} />
				</Route>
				
				<Route  path='/login' element={<LoginScreen/>} />
				<Route  path='/otp-verify' element={<VerifyOTP/>} />
			</Routes>
		);
}

export default AppRoutes;