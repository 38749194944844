import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
import Images from '../../static/images';
import {TitleHeader} from '../../components/header';

import {getSource} from '../../components/functions';

const ContactUS = () => {
	
	const phoneNumber = "9460178308";
	const message     = "Hello";
	// const shareUrl = `whatsapp://send/?${phoneNumber}?text=${encodeURIComponent(message)}`;
	const whatDevice = getSource();
	
	const shareUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
	
	if(whatDevice){
		shareUrl =  `whatsapp://send/?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
	}
	
	return (
		<div className="">
			<TitleHeader title={"Help Center"} />
			
			<div className="mt-2">
		    	<h6 className="h5 ml-4">Contact Us</h6>
		    	<span className="text-secondory ml-4">How do you wish to contact us</span>
		    </div>
		    <div className="container">
		    	<div className="row text-center mt-4">
				  <div className="col-6 col-md-3">
				    <div className="card shadow border-0 mb-3">
				    <Link to={shareUrl}>
				      <div className="card-body">
				          <img className="" src={Images.whatsapp_2} style={{maxWidth:'30px'}}/>
				        <h6 className="mt-3 mb-0 font-weight-normal text-dark">Chat with Us {getSource()}</h6>
				    </div>
				    </Link>
				    </div>
				  </div>
				  <div className="col-6 col-md-3">
				    <div className="card shadow border-0 mb-3">
				    <Link to={"tel:" + phoneNumber}>
				      <div className="card-body">
				        <img className="" src={Images.call} style={{maxWidth:'30px'}} />
				        <h6 className="mt-3 mb-0 font-weight-normal text-dark">Call Us</h6>
				    </div>
				    </Link>
				  </div>
				</div>
		{/*		<div className="col-6 col-md-3">
				    <div className="card shadow border-0 mb-3">
				      <div className="card-body">
				        <img className="" src={Images.write} style={{maxWidth:'30px'}} />
				        <h6 className="mt-3 mb-0 font-weight-normal text-dark">Write To Us</h6>
				    </div>
				  </div>
				</div>*/}
			   </div>
		    </div>
		</div>
	);
	
};

export default ContactUS;