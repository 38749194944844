import React,{ useState,useEffect } from 'react';
import {Link,useParams,useLocation,useNavigate} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import {handleError} from '../../services/axios';
import Images from '../../static/images';
import {TitleHeader} from '../../components/header';
import {getMobileOperatingSystem} from '../../components/functions';
import RingLoader from '../../components/ringLoader';
import {success ,error} from '../../components/notification';


const device = getMobileOperatingSystem();

const showBottomSheet = () => {
    document.querySelector('.bottom-sheet-wrapper').classList.toggle('show');
    document.querySelector('.bottom-sheet-wrapper').classList.toggle('d-none');
    // document.body.classList.toggle("overflow-hidden");
};

const Wallet = () => {
	const navigate = useNavigate();
	const [amount ,setAmount]					= useState(0);
	const [wallet ,setWallet]  = useState(null);
	const [loader, setLoader] = useState(true);

	
	const GetPaymentHistory = () => {
 		axios.get(`/get-wallet`,{params:{history:true}}).then(res => {
			let data = res.data;
			setWallet(data.wallet)
			setLoader(false)
		}).catch(handleError)
	}
	
	 useEffect(() => {
	    GetPaymentHistory()
	}, []); //
	
	const goToPaymentDetails = (item) => {
		console.log(item)
		navigate("/payment-details",{state:{transaction_id:item.transaction_id}})	
	}
	
	const onChangeInput = (type,value) => {
		if(type == "amount")
			setAmount(value)
	}
	
	const handlePayment = async () => {
	    try {
	      const response = await axios.post('/initiate-payment', {
	        amount,
	      }).then(res => {
	      	 let data = res.data;
        		 if(data.success)
  				window.location.href = data.pay_url;
  	    	 else
  	    		error(data.message);
  	    		
	      }).catch(handleError)
	
	    } catch (error) {
	      console.error('Payment initiation error:', error);
	    }
    };
    
     
	return (
		<>
		<TitleHeader title={"WALLET"}/>
		{ loader ?	<RingLoader/> : '' }
			{wallet == null ? ""  : 
			<>
		
			 <div className="container">
				  <div className="card mb-4 shadow">
				    <div className="card-body border-bottom">
				      <div className="row">
				        <div className="col">
				          <h3 className="mb-0 font-weight-normal">₹ {wallet.balance}</h3>
				          <p className="text-mute">My Balance</p>
				        </div>
				        <div className="col-auto">
				          <button
				            className="buttonPrimary"
				            onClick={() => showBottomSheet()}
				          >
				            Add Credit
				          </button>
				          	
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
		
				<div className="container">
				  <h6 className="subtitle">
				    Transaction History{" "}
				    {/*<a href="alltransactions.html" className="float-right small">
				      View All
				    </a>*/}
				  </h6>
				  <div className="row transaction-list">
				    <div className="col-12 px-0">
				    	{
				    		wallet.payment_history.map((item,index) => {
				    			return  <div className="card shadow border-0 mb-3" key={index} onClick={() => item.source == "wallet" ? goToPaymentDetails(item) : null}>
											  <div className="card-body">
											    <div className="row align-items-center">
											      <div className="col-auto pr-0">
											        <div className="avatar avatar-20 no-shadow border-0">
											          <img className={item.type == "cr" ? "icon-credit" : "icon-debit"} src={item.type == "cr" ? Images.icon_credit : Images.icon_debit} alt="" />
											        </div>
											      </div>
											      <div className="col pr-0">
											        <h6 className="font-weight-normal mb-1">{item.remark}</h6>
											        <p className="text-mute small text-secondary">
											        	
											        	
											        	{
											        		item.status == "pending" ? 
											        			<badge class="badge badge-warning">{item.status}</badge> 
											        		:  item.status == "completed" ? 
											        			<badge class="badge badge-success">{item.status}</badge> 
											        		: 
											        			<badge class="badge badge-danger">{item.status}</badge> 
											        	}
											        
												         {" "} | {moment.utc(item.created_at).format("DD-MMM-YYYY HH:mm:ss")}</p>
											      </div>
											      <div className="col-auto">
        								              <h6 className={item.type == "cr" ? "text-success" : "text-danger"}> {item.type == "cr" ? "" : "-"} ₹ {item.total_amount.toFixed(2)}</h6>
											      </div>
											    </div>
											  </div>
											</div>
				    		})
				    	}
				    </div>
				  </div>
				</div>
				<div className={"bottom-sheet-wrapper d-none"} >
				  <div className="backdrop" onClick={() =>  showBottomSheet()}  />
				  <div className="bottom-sheet" className={ device == "iOS" ? "bottom-sheet-ios" : "bottom-sheet"}>
				    <div className="loginForm" style={{paddingTop:15}}>
	        			<div className="loginForm_inner mb-0">
						    <div className="contentDiv">
						    	<div className="row mb-3">
						    		<div className="col-6">
					                	<h5 className="Heading" style={{float:'left'}}>Credits</h5>
					                </div>
				                	<div className="col-6">
					                	<span style={{float:'right'}}>1 credit = 1 INR</span>
					                </div>
				                </div>
				                <div className="inputbox">
				                    <input type="number" className="form-control validate-name" placeholder="Enter Amount" value={amount} onChange={(e) => onChangeInput("amount",e.target.value)} />
				                </div>
				                <div className="mt-4">
					                <div className="container chips-container">
									  <button className="btn btn-rounded-15 tab-content tab-pane shadow-sm" onClick={() => setAmount(500)}> 500</button>
									  <button className="btn btn-rounded-15 tab-content tab-pane shadow-sm" onClick={() => setAmount(1000)}> 1000</button>
									  <button className="btn btn-rounded-15 tab-content tab-pane shadow-sm" onClick={() => setAmount(1500)}> 1500</button>
									  <button className="btn btn-rounded-15 tab-content tab-pane shadow-sm" onClick={() => setAmount(2000)}> 2000</button>
									</div>
								</div>
							
				                
				                <div>
					                <span className="Heading-15px" style={{float:'left'}}>Total Price</span>
					                <h5 style={{float:'right'}}>₹ {amount}</h5>
			                		<button className="saveBtn" onClick={() => handlePayment()}>Proceed to Pay</button>
			                	</div>
				            </div>
				         </div>
					</div>
				  </div>
				</div>
	        
			</>
			}
		</>
		);
}

export default Wallet;