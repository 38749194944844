import React,{useEffect,useState} from 'react';
import {Link,useParams,useNavigate  } from 'react-router-dom';
import axios from 'axios';
import Images from '../../static/images';
import {handleError} from '../../services/axios';
import {AllNumbersKeyUp ,DotAndNumbersKeyUp} from '../../components/functions';
import {success ,error} from '../../components/notification';

import '../../assets/loginstyle.css';

const Login = () => {
	const navigate = useNavigate();
    const {} = useParams();
	const [mobile, setMobile] = useState(null);


	const SubmitUserLogin = () => {
 		axios.post(`https://api.eeil.online/api-v1/user/login`,{mobile_no:mobile}).then(res => {
			let data = res.data;
			if(data.success){
				success(data.message);
		    	navigate("/otp-verify",{state:{mobile_no:mobile}});
			}else{
				error(data.message);
			}
		}).catch(handleError)
	}

	useEffect(() => {
	   window.location.href="https://play.google.com/store/apps/details?id=com.eeil.ecoplug&hl=en"
	}, []); 
 
	const onChangeInput = (e) => {
		let value  = e.target.value
		setMobile(value);
	}
	return (
		<div className="loginForm">
		        <div className="loginForm_inner mb-0">
		            <div className="logoDiv">
		                <img src={Images.logo} alt="" />
		            </div>
		            <div className="contentDiv">
		                <h2 className="Heading">What’s Your Phone Number?</h2>
		                <div className="inputbox">
		                    <div class="input-group-prepend">
							 <span class="input-group-text" id="basic-addon1">+91</span>
							</div>
		                    <input type="tel" value={mobile} 
		                    	className="form-control validate-name" 
		                    	placeholder="Enter Mobile No."  
		                    	onChange={(value) => onChangeInput(value)}
						        onKeyDown={AllNumbersKeyUp}
							/>
		                </div>
		                <span>
		                	<button className="saveBtn" onClick={() => SubmitUserLogin()}>Send OTP</button>
		                </span>
		            </div>
		        </div>
		    </div>
	)
}
 
export default Login;