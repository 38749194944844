import axios from 'axios'

axios.defaults.baseURL = 'https://api.eeil.online/api-v1/user'

axios.interceptors.request.use(
    async config => {
         let user = localStorage.getItem("user");
         const token = user != null ? JSON.parse(user).token : "";
         
         if (token != null)
             config.headers.Authorization = "Bearer "+token;
            // config.headers['X-API-KEY'] = "NCSvUC#2A6tLJ8Zs@KXPdnWIk2&K4d9FNWL";
            config.headers['Access-Control-Allow-Origin'] = true;
        return config
    },
    async error => {
    	console.log("error",error)
        if (401 === error.response.status) {
        	console.log("ddkdiidjjd",error.response);
        	localStorage.clear();
            window.location.href="/login";
        	
        } else {
            return Promise.reject(error)
        }
    }
);

function handleError(error) {
	console.log("error",error)
  if (401 === error.response.status) {
	localStorage.clear();
    window.location.href="/login";
  }
}
export {axios,handleError};