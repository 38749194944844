import React from 'react';
import { Link  } from "react-router-dom";
import Images from '../static/images';

function SideBarToggle(){
	document.body.classList.toggle("sidemenu-open");
}

function Header(){
	return (
			<div className="header">
	            <div className="row no-gutters">
	                <div className="col-auto">
	                    <button className="btn  btn-link text-dark menu-btn" onClick={() => SideBarToggle()}><i className="material-icons">menu</i><span className="new-notification"></span></button>
	                </div>
	                <div className="col text-center">
	                	<img src="http://crm.eeil.online/static/media/logo-new.121c36e3c5c3fee1c41e.png" alt="" className="header-logo"/>
                	</div>
	                <div className="col-auto">
	                    <a href="notification.html" className="btn  btn-link text-dark position-relative"><i className="material-icons">notifications_none</i><span className="counts">9+</span></a>
	                </div>
	            </div>
	        </div>
		);
}


const TitleHeader = ({title}) =>  {
 	return (
			<div className="header">
	            <div className="row no-gutters">
	                <div className="col-auto item-flex">
	                	<img src={Images.icon_back} alt="" className="icon-back" onClick={() => window.history.go(-1)}/>
	                	<span className="header-title">{title.substr(0,30)}{title.length > 30 ? "..." :""}</span>
	                </div>
	                <div className="col text-center p-3">
                	</div>
	                <div className="col-auto">
	                </div>
	            </div>
	        </div>
		);
}


export  {Header,TitleHeader};