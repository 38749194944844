import React,{ useState } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import {axios,handleError} from '../../services/axios';
import {success ,error} from '../../components/notification';

// import '../assets/loginstyle.css';

const UserDetailScreen = () => {
	const navigate		= useNavigate();

	const [params, setParams] = useState({});
	
	const onInputChange = (type,value) => {
		params[type] = value;
		setParams(params);
	}
	
	const onSubmitBasicForm = () => {
 		axios.post(`/update-user`,params).then(res => {
			let data = res.data;
			if(data.success){
				 success(data.message)
			  	 navigate("/");
			}else{
				error(data.message);
			}
		}).catch(handleError)
	}
	
	return(
	<div className="loginForm">
        <div className="loginForm_inner mb-0">
            <div className="contentDiv">
                <h2 className="Heading">Tell Us About YourSelf</h2>
                <div className="inputbox">
                    <input type="text" className="form-control validate-name" onChange={(e) => onInputChange("name",e.target.value)} placeholder="Enter Name" />
                </div>
                <div className="inputbox">
                    <input type="email" className="form-control validate-name" onChange={(e) => onInputChange("email",e.target.value)} placeholder="Enter Email" />
                </div>
                {/*<div className="inputbox">
                    <input type="text" className="form-control validate-name" onChange={(e) => onInputChange("name",e.target.value)} placeholder="Enter Code" />
                </div>*/}
            	<button className="saveBtn" onClick={() => onSubmitBasicForm()}>Save Information</button>
            </div>
        </div>
    </div>
	 );
}
    
export default UserDetailScreen;